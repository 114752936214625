    <mat-card class="tab-container">
        <mat-card-header>
            <mat-card-title>Jobs</mat-card-title>
            <button mat-raised-button color="primary" class="create-button" (click)="openDialog()" matTooltip="Import a job">Import New
                Job</button>
        </mat-card-header>


        <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-align-tabs="start">
            <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.link" routerLinkActive
                #rla="routerLinkActive" [active]="rla.isActive" class="tab-link">
                <h3>{{link.label}}</h3>
            </a>
        </nav>
        <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
        </mat-tab-nav-panel>
    </mat-card>
