<div>
    <div role="button" tabindex="0" *ngIf="!isEditing; else editing" (click)="onClick()" (keyup.enter)="onClick()"
        (keydown.enter)="onClick()" class="flex">
        <span>
            {{noteFormControl.value? noteFormControl.value : 'Placeholder...'}}
        </span>
    </div>

    <ng-template #editing>
        <mat-form-field appearance="outline" class="note-width no-border" subscriptSizing="dynamic">
            <textarea #noteInputRef matInput [formControl]="noteFormControl" type="text" required cdkTextareaAutosize
                (input)="onNoteStringChange()" (blur)="onBlur()" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1">
            </textarea>
        </mat-form-field>
        <div class="flex justify-content-end">
            <button mat-stroked-button color="warn" (mousedown)="onDelete()" class="padding-top-10">
                Delete
            </button>
        </div>
    </ng-template>

</div>