import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { SkuMappingChangeModel } from 'src/app/core/models/skuMappingChangeModel';

@Component({
  selector: 'app-view-change',
  templateUrl: './view-change.component.html',
  styleUrls: ['./view-change.component.scss'],
})
export class ViewChangeComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input()
  data: SkuMappingChangeModel[] = [];

  @Output()
  changeSelectionEvent: EventEmitter<SkuMappingChangeModel[]> = new EventEmitter();

  selection = new SelectionModel<SkuMappingChangeModel>(true, []);
  dataSource: MatTableDataSource<SkuMappingChangeModel>;
  expandedRows = new SelectionModel<SkuMappingChangeModel>(true, []);

  subscriptions: Subscription = new Subscription();

  displayedColumns = [
    'select',
    'solidBuilderSku',
    'solidBuilderDescription',
    'productCode',
    'description',
    'productUseDescription',
    'sellUnit',
    'status',
  ];

  ngOnInit(): void {
    this.subscriptions.add(
      this.selection.changed.subscribe(() => {
        this.changeSelectionEvent.emit(this.selection.selected);
      })
    );
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected(): boolean {
    return this.selection.selected.length === this.data.length;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
