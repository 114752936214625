<h1 mat-dialog-title class="card-title">{{ formName }}</h1>

<div class="search-box">
  <app-product-search
    [isActive]="activeProductSearch"
    (productChangedEvent)="onProductChange($event)"
    class="product-search"
  ></app-product-search>
</div>

<div mat-dialog-content class="p-t-20">
  <form [formGroup]="form" class="flex-column">
    <mat-form-field appearance="outline" class="flex-grow-1">
      <mat-label> SB SKU </mat-label>
      <input
        matInput
        formControlName="solidBuilderSku"
        type="text"
        required
        maxlength="9"
      />
      <mat-error *ngIf="form.controls['solidBuilderSku'].hasError('required')">
        Solid Builder SKU is requried
      </mat-error>

      <mat-error *ngIf="form.controls['solidBuilderSku'].hasError('minlength')">
        Solid Builder SKU needs to have 9 digits
      </mat-error>

      <mat-error *ngIf="form.controls['solidBuilderSku'].hasError('pattern')">
        Solid Builder SKU only allows numberic values
      </mat-error>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="flex-grow-1"
    >
      <mat-label> SB Description </mat-label>

      <textarea
        matInput
        formControlName="solidBuilderDescription"
        type="text"
        rows="3"
        required
        class="max-height-textarea"
      ></textarea>
    </mat-form-field>
    <app-unit-selector class="margin-bottom-10"
    label="SB Unit"
      formControlName="sourceUnitOfMeasure"
    ></app-unit-selector>
    <mat-form-field appearance="outline" class="flex-grow-1">
      <mat-label> ACE SKU </mat-label>
      <input
        matInput
        formControlName="productCode"
        type="number"
        required
        readonly
      />
      <button
        matSuffix
        mat-icon-button
        class="close-button"
        [disabled]="activeProductSearch"
        (click)="displayProductSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field
      formGroupName="product"
      appearance="outline"
      class="flex-grow-1"
    >
      <mat-label> Description </mat-label>

      <textarea
        matInput
        formControlName="description"
        type="text"
        rows="3"
        required
        readonly
        class="max-height-textarea"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-grow-1">
      <mat-label> Product Use Description </mat-label>
      <textarea
        matInput
        formControlName="productUseDescription"
        type="text"
        rows="3"
        class="max-height-textarea"
      ></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="footer">
  <div>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      (click)="onSave()"
    >
      <span *ngIf="!savingChange; else savingChangeSpinner">Save</span>
      <ng-template #savingChangeSpinner>
        <mat-spinner
          mode="indeterminate"
          diameter="30"
          color="accent"
        ></mat-spinner>
      </ng-template>
    </button>
    <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
  </div>
</div>
