<div class="flex-column align-items-center">

    <mat-form-field class="search-input-field">
        <mat-label>Job Number</mat-label>
        <input matInput type="text" [matAutocomplete]="auto" [formControl]="searchControl"
            (input)="onSearchInputChange()">
        <div matSuffix class="flex align-items-center justify-content-center w-48">
            <mat-spinner *ngIf="isSearching; else notSearching" matSuffix [diameter]="30"></mat-spinner>
            <ng-template #notSearching>
                <button matSuffix mat-icon-button (click)="onClear()">
                    <mat-icon>clear</mat-icon>
                </button>
            </ng-template>
        </div>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)"
            class="mat-autocomplete-panel">

            <div *ngIf="searched">
                <div *ngIf="!isSearching">
                    <mat-option *ngIf="jobsFound.length === 0; else hasJobfound">
                        No Results!
                    </mat-option>
                    <ng-template #hasJobfound>
                        <mat-option *ngFor="let option of jobsFound" [value]="option.id">
                            {{option.projectNumber}} &nbsp; {{option.branch.name}} ({{option.branch.id}}) &nbsp;
                            v{{option.versionNumber}}
                        </mat-option>

                    </ng-template>
                </div>
            </div>

        </mat-autocomplete>
    </mat-form-field>

    <div *ngIf="selectedOption" class="job-search-width">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{selectedOption.projectName}} | {{selectedOption.projectNumber}}</mat-card-title>
                <mat-card-subtitle>
                    
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="card-details">
                    <span class="padding-right-16"><b>Builder: </b>{{selectedOption.builder?.name}}</span>
                    <span class="padding-right-16"><b>Address: </b>{{selectedOption | location}} </span>
                    <span ><b>Branch: </b>{{selectedOption.branch.name}}</span>
                </div>
            </mat-card-content>
        </mat-card>
        <div *ngIf="selectedOption.importedById" class="flex align-items-center warning">
            <mat-icon color="warn" class="icon">error</mat-icon>
            <span color="warn" class="text">This job is already imported by {{selectedOption.importedBy?.firstName}} {{selectedOption.importedBy?.lastName}}!</span>
        </div>
    </div>
   

</div>