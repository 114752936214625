<mat-toolbar class="mat-elevation-z3 toolbar" color="secondary">
    <img tabindex="0" class="logo" src="../../assets/placemakers_menu_logo.jpg" alt="placemakers log"
        (click)="navigateTo('/')" (keyup.enter)="navigateTo('/')" (keydown.enter)="navigateTo('/')">
    <h1 class="title">SKU Mapping Tool</h1>
    <span class="user-icon"><mat-icon>account_circle</mat-icon></span>
    <div class="user-info">
        <p class="user-name">{{ (auth.getLoggedInUser() | async)?.email }}</p>
    </div>

    <button class="menu" mat-icon-button #menuTrigger="matMenuTrigger" (mouseover)="menuTrigger.openMenu()" (focus)="menuTrigger.openMenu()" [matMenuTriggerFor]="menu">
        <mat-icon>widgets</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <span (mouseleave)="menuTrigger.closeMenu()">
            <button mat-menu-item (click)="navigateTo('/myJobs')">
                <mat-icon>article</mat-icon>
                <span>Jobs</span>
            </button>
            <button mat-menu-item *ngIf="showMenu" (click)="navigateTo('/skuMappings')">
                <mat-icon>admin_panel_settings</mat-icon>
                <span>Sku Mapping</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Log out</span>
            </button>
        </span>
    </mat-menu>
</mat-toolbar>