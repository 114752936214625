<h1 mat-dialog-title class="card-title">Bulk import SKUs</h1>

<div class="card-content">
    <app-upload-file class="flex-grow" (uploadFileEvent)="onUploadFile($event)"
    (fileDeleteEvent)="onDeleteFile()"></app-upload-file>

</div>


<div mat-dialog-actions class="p-x-24 justify-content-between footer">
    <div>
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="onSave()">
            <span *ngIf="!savingChange; else savingChangeSpinner">Import</span>
            <ng-template #savingChangeSpinner>
                <mat-spinner mode="indeterminate" diameter="30" color="accent"></mat-spinner>
            </ng-template>
        </button>
        <button mat-stroked-button color="primary" mat-dialog-close [disabled]="savingChange">Cancel</button>
    </div>

</div>