<mat-card>
    <mat-card-title class="note-title">
        <div class="flex align-items-center">
            <h3>Notes</h3>
        </div>
        <button mat-icon-button color="primary" (click)="onCloseNote()">
            <mat-icon>
                close
            </mat-icon>
        </button>
    </mat-card-title>
    <mat-card-content *ngIf="notes" class="note-content">
        <ng-container *ngIf="!rearrangeNote;else draggableNote">
            <div *ngFor="let note of notes; let i = index">
                <app-note [note]="note" [isNoteReadOnly]="readOnly || newNoteIndex !== -1" (editNoteEvent)="onToggleNoteEdit($event)"></app-note>
                <app-new-note *ngIf="newNoteIndex === i;else addButton" [jobId]="jobId" [posIndex]="i+1"
                    (newNoteEvent)="onNewNoteActionComplete()"></app-new-note>
                <ng-template #addButton>
                    <div class="new-note">
                        <mat-icon *ngIf="!readOnly && !editNote" color="accent" (click)="onAddNote(i)" class="visibility">
                            add_circle
                        </mat-icon>
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #draggableNote>
            <div cdkDropList (cdkDropListDropped)="dropNote($event)" class="list">
                <div *ngFor="let note of notes" cdkDrag cdkDragBoundary="mat-drawer" class="box">
                    <div class="custom-placeholder" *cdkDragPlaceholder></div>
                    <app-note [note]="note" [isNoteReadOnly]="true"></app-note>
                </div>
            </div>
        </ng-template>
    </mat-card-content>
    <mat-card-actions class="justify-content-end" *ngIf="!readOnly">
        <button *ngIf="!rearrangeNote" mat-stroked-button color="accent" (click)="onRearrangeNote()"
            matTooltip="Rearrange notes">
            Rearrange Notes
        </button>
        <button *ngIf="rearrangeNote" mat-raised-button extended color="accent" (click)="onSaveNotesArrangement()"
            class="white-text" matTooltip="Save arrangement changes">
            Save Arrangement
        </button>
    </mat-card-actions>
</mat-card>