import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { NewJobDialogComponent } from './new-job-dialog/new-job-dialog.component';
import { SearchJobComponent } from './search-job/search-job.component';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from "../shared-components/shared.module";


@NgModule({
    declarations: [
        NewJobDialogComponent,
        SearchJobComponent,
    ],
    exports: [
        NewJobDialogComponent
    ],
    imports: [
        CommonModule,
        MatStepperModule,
        MatIconModule,
        ReactiveFormsModule,
        MatListModule,
        MatProgressBarModule,
        MatButtonModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCardModule,
        MatProgressSpinnerModule,
        SharedModule
    ]
})
export class NewJobDialogModule { }
