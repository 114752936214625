import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { SuperDivisionModel } from 'src/app/core/models/SuperDivisionModel';
import { DivisionService } from 'src/app/core/services/division.service';
@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CategorySelectorComponent
    }
  ]
})
export class CategorySelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  categories: SuperDivisionModel[] = [];

  selectedCategory = new FormControl('');

  @Output()
  selectCategory: EventEmitter<SuperDivisionModel> = new EventEmitter<SuperDivisionModel>();

  subscriptions: Subscription = new Subscription();

  constructor(
    private divisionService: DivisionService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.divisionService.getSuperDivisions().subscribe(
      result => {        
        this.categories = result.filter(category => !category.deleted);        
        this.selectedCategory.value && this.emitCategory(this.selectedCategory.value);
      }
    ));

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectionChanged(event: MatSelectChange) {
    this.propagateChange(event.value);
    this.emitCategory(event.value);
  }

  writeValue(defaultSelection: string): void {    
    this.selectedCategory.setValue(defaultSelection);
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {    
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(_fn: any): void {
    // Not used
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.selectedCategory.disable() : this.selectedCategory.enable();
  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  // eslint-disable-next-line
  private propagateChange = (_: any) => {
    // Placeholder
  };

  private emitCategory(categoryName: string){
    const selectedCategory = this.categories.find(category => category.name === categoryName);    
    if (selectedCategory) {
      this.selectCategory.emit(selectedCategory);
    }
  }

}
