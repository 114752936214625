import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobNotePayloadModel } from '../models/JobNotePayloadModel';
import { JobNoteModel } from '../models/JobNoteModel';
import { Constants } from '../constants/constants';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root',
})
export class JobNoteService {
  constructor(private apiRequestService: ApiRequestService) {}

  public updateJobNote(jobNoteId: number, payload: JobNotePayloadModel) {
    const url = `${Constants.JOB_NOTE_API_URL}/${jobNoteId}`;
    return this.apiRequestService.put(
      url,
      { ...payload },
      undefined, //No request options
      Constants.UPDATE_NOTE_ERROR
    );
  }

  public addJobNote(payload: JobNotePayloadModel): Observable<JobNoteModel> {
    return this.apiRequestService.post<JobNoteModel>(
      Constants.JOB_NOTE_API_URL,
      { ...payload },
      undefined, //No request options
      Constants.ADD_NOTE_ERROR
    );
  }

  public rearrangeNotes(payload: JobNotePayloadModel[]): Observable<unknown> {
    const url = `${Constants.JOB_NOTE_API_URL}/Reorder`;
    return this.apiRequestService.put(
      url,
      payload,
      undefined, //No request options
      Constants.REARRANGE_NOTES_ERROR
    );
  }

  public deleteJobNote(jobNoteId: number): Observable<unknown> {
    const url = `${Constants.JOB_NOTE_API_URL}/${jobNoteId}`;
    return this.apiRequestService.delete(
      url,
      undefined, //No request options
      Constants.DELETE_NOTE_ERROR
    );
  }
}
