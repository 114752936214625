import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, finalize } from 'rxjs';
import { Constants } from 'src/app/core/constants/constants';
import { Product } from 'src/app/core/models/Product';
import { SkuMappingModel } from 'src/app/core/models/SkuMappingModel';
import { AlertService } from 'src/app/core/services/alert.service';
import { SkuMappingService } from 'src/app/core/services/sku-mapping.service';
@Component({
  selector: 'app-sku-mapping-dialog',
  templateUrl: './sku-mapping-dialog.component.html',
  styleUrls: ['./sku-mapping-dialog.component.scss'],
})
export class SkuMappingDialogComponent implements OnInit {
  formName = this.data ? 'Edit SKU Mapping' : 'Add SKU Mapping';

  activeProductSearch = this.data ? false : true;

  form: FormGroup = this.formBuilder.group({
    solidBuilderSku: [
      '',
      [
        Validators.required,
        Validators.pattern(/^\d+$/),
        Validators.minLength(9),
        Validators.maxLength(9),
      ],
    ],
    solidBuilderDescription: ['', [Validators.required]],
    productCode: ['', Validators.required],
    product: this.formBuilder.group({
      productCode: ['', Validators.required],
      description: ['', Validators.required],
      sellUnit: ['', Validators.required],
      status: ['', Validators.required],
    }),
    productUseDescription: [''],
    sourceUnitOfMeasure: ['', Validators.required],
  });

  savingChange = false;

  subscriptions$: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SkuMappingModel | null,
    private dialogRef: MatDialogRef<SkuMappingDialogComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private skuMappingService: SkuMappingService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.form.patchValue(this.data);
    } else {
      this.form.disable();
    }
  }

  onSave() {
    if (!this.form.valid) {
      return;
    }

    this.savingChange = true;

    const { solidBuilderSku, ...otherFields } = this.form.value;
    const payload = { ...otherFields, solidBuilderSku: `${solidBuilderSku}` };

    let request = this.skuMappingService.createNewSkuMapping(payload);

    if (this.data) {
      request = this.skuMappingService.updateSkuMapping(this.data.id, payload);
    }

    this.subscriptions$.add(
      request
        .pipe(
          finalize(() => {
            this.savingChange = false;
          })
        )
        .subscribe(() => {
          const successMessage = this.data
            ? Constants.MAPPING_UPDATED
            : Constants.MAPPING_ADDED;
          this.alertService.showSuccessMessage(successMessage);
          this.dialogRef.close({event:successMessage, data:payload});
        })
    );
  }

  onProductChange(product: Product) {
    this.form.patchValue({
      product: { ...product },
      productCode: product.productCode,
    });
    this.form.enable();

    this.activeProductSearch = false;
  }

  displayProductSearch() {
    this.form.patchValue({
      productCode: '',
      product: {
        productCode: '',
        description: '',
        sellUnit: '',
        status: '',
      },
    }),
      (this.activeProductSearch = true);
  }
}
