import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductEditingDialogComponent } from '../product-editing-dialog/product-editing-dialog.component';
import { EstimateLineModel } from 'src/app/core/models/EstimateLineModel';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { DeleteService } from 'src/app/core/services/delete.service';
import { Subscription, concat, finalize, toArray } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { JobService } from 'src/app/core/services/job.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ProductService } from 'src/app/core/services/product.service';
import { SuperDivisionModel } from 'src/app/core/models/SuperDivisionModel';
import { ProductTreeNode } from 'src/app/core/models/ProductTreeNode';
import { JobDetailsService } from 'src/app/core/services/job-details.service';
import { Product } from 'src/app/core/models/Product';
import { ProductSearchComponent } from '../product-search/product-search.component';
import { InlineEditService } from 'src/app/core/services/inline-edit.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssemblyDivisionModel } from 'src/app/core/models/AssemblyDivisionModel';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit, AfterContentInit, OnDestroy, AfterViewInit {
  productEditForm!: FormGroup;
  tooltip = 'Incorrect sku';

  rowHeight: string;
  hideCheckbox = true;
  editProductItem = false;
  activeProductSearch = false;
  toDisableSaveBtn = false;
  productFromSearch: Product | null = null;

  @ViewChild('skuCheckbox') skuCheckbox: MatCheckbox;
  @ViewChild(ProductSearchComponent) searchComponent: ProductSearchComponent;

  @Input()
  productItem: EstimateLineModel;
  @Input()
  isProductReadonly = false;

  @Output() editProduct: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscriptions$: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private deleteService: DeleteService,
    private jobService: JobService,
    private productService: ProductService,
    private alertService: AlertService,
    private jobDetailsSerivce: JobDetailsService,
    private elementRef: ElementRef,
    private fb: FormBuilder,
    public inlineEditService: InlineEditService) { }

  ngOnInit() {
    this.subscriptions$.add(this.deleteService.checkBoxVisibilityChange$.subscribe(value => {
      this.hideCheckbox = value;
      if(this.skuCheckbox)
        this.skuCheckbox.checked = false;
    }));
  }

  private initForm(item: EstimateLineModel) {
    this.productEditForm = this.fb.group({
      sku: this.fb.control(item.sku, [Validators.required]),
      description: this.fb.control({value: item.description, disabled: this.productItem.sku ? false : true},[Validators.required]),
      productUseDescription: this.fb.control({value: item.productUseDescription, disabled: this.productItem.sku ? false : true}),
      quantity: this.fb.control({value: item.quantity, disabled: this.productItem.sku ? false : true}, [this.quantityValidator.bind(this)]),
      unit: this.fb.control(item.unit),
    });
  }

  quantityValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value === 0 || control.value === null) {
      return { invalidQuantity: true };
    }
    return null;
  }

  ngAfterContentInit() {
    this.subscriptions$.add(this.deleteService.selectSKUItems$.subscribe((item) => {
      if(Object.keys(item).length > 0 && this.skuCheckbox) {
        if(item?.skuList?.includes(this.productItem.id)){
          this.skuCheckbox.checked = item?.toSelect;
        } else {
          this.skuCheckbox.checked = this.deleteService.selectedList.includes(this.productItem.id);
        }

        const myEvent: MatCheckboxChange = {
            checked: this.skuCheckbox.checked,
            source: this.skuCheckbox,
          };
        this.skuCheckbox.change.emit(myEvent);
      }
    }));
  }

  ngAfterViewInit() {
    this.calculateRowHeight();
  }

  calculateRowHeight() {
    const descriptionElement = this.elementRef.nativeElement.querySelector('.description');
    const productUseDescriptionElement = this.elementRef.nativeElement.querySelector('.use-description');

    this.rowHeight = `${Math.max(descriptionElement.offsetHeight, productUseDescriptionElement.offsetHeight) + 40}px`;
  }

  onEdit(event: MouseEvent, product: EstimateLineModel, option = 'product') {
    event?.stopPropagation();
    /* if((<HTMLElement>event.srcElement).className === "") {
      return
    } */

    if(this.isProductReadonly){
      return;
    }

    if(this.inlineEditService.getRowBeingEdited()?.id !== product.id)
    {
      this.inlineEditService.startEditing(product);
      if(option === 'category') {
        const dialogRef = this.dialog.open(ProductEditingDialogComponent, {
          data: product,
          width: '40%',
          disableClose: true
        })
        dialogRef.afterClosed().subscribe(() =>
          this.inlineEditService.endEditing()
        );
      } else {
        this.initForm(product);
        this.toggleEditProductItem(true);
      }
    }
  }

  checkItems(check: boolean, itemId: number) {
    this.deleteService.selectedItemListToDelete(check, itemId);
  }

  getColour(item : EstimateLineModel) {
    if (item.description?.includes('**') || item.productUseDescription?.includes('**')) {
      this.tooltip = '** sku';
      return '#ffd520';
    }
    if (item.sku && item.sku?.length < 6 && !item.productCode) {
      this.tooltip = 'Fudged sku';
      return '#cb8836';
    }
    if (item.product && (item.product.status.toLowerCase() === 'i' || item.product.status.toLowerCase() === 'd')){
      this.tooltip = 'Inactive sku';
      return '#a4a7a9';
    }
    if (item.sku && item.product && item.product.status.toLowerCase() === 'a' && item.sku?.length < 8 && item.quantity > 0) {
      this.tooltip = 'Active sku';
      return '#003976';
    }

    return '#ce1141';
  }

  onCancel() {
    event?.stopPropagation();

    this.productEditForm.patchValue({
      sku: this.productItem.sku,
      description: this.productItem.description,
      productUseDescription: this.productItem.productUseDescription,
      quantity: this.productItem.quantity,
      unit: this.productItem.unit
    });

    this.activeProductSearch = false;
    this.productFromSearch = null;
    this.searchComponent && this.searchComponent.clearSelection();

    this.inlineEditService.endEditing();
    this.toggleEditProductItem(false);
    this.onInputChange();
  }

  async onSave() {
    event?.stopPropagation();
    const product = await this.jobDetailsSerivce.getProductByID(this.productItem.id);

    const saveItem = {
      ...product,
      ...this.productItem,
      ...this.productEditForm.value,
      sku: String(this.productEditForm.value.sku)
    }

    if (saveItem.sku && saveItem.sku.length < 6) {
      saveItem.product = null;
      saveItem.productCode = null;
    }

    if(this.productFromSearch) {
      saveItem.product = this.productFromSearch;
      saveItem.productCode = this.productFromSearch.productCode;
    }

    const requestPayload = { ...saveItem };

    const updateEstimateLineRequest = this.productService.updateProductById(this.productItem.id, requestPayload);
    const retrieveEstimateLinesRequest = this.jobService.getProjectsByJobId(this.productItem.jobId);

    this.subscriptions$.add(concat(updateEstimateLineRequest, retrieveEstimateLinesRequest).pipe(
      toArray(),
      finalize(() => {
        this.onCancel();
      })
      ).subscribe(
        (data) => {
          this.alertService.showSuccessMessage('Product updated Successfully.');
          this.dialog.closeAll();

          const treeData = data[1] as SuperDivisionModel[];
          this.setExpandList(treeData, requestPayload);

          const allDivisions: AssemblyDivisionModel[] = [];
          treeData.flatMap(item => item.assemblyDivisions?.flatMap(division => {
            allDivisions.push(division);
          }));
          const estimateLine = (allDivisions as any).flatMap((item: any) => item.estimateLines).find((line: any) => line?.id == this.productItem.id);
          this.jobDetailsSerivce.updateProductInTree(estimateLine);

          this.productItem = { ...requestPayload};
        }
      ))
  }

  onDelete(productItem: EstimateLineModel) {
    event?.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Confirm delete",
        message: `Are you sure you want to delete SKU ${productItem.sku}?`,
        successButtonLabel: "Confirm"
      },
      width: '40%',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirmed') {
        this.deleteProduct(productItem);
      }
    });
  }

  private async deleteProduct(productItem: EstimateLineModel) {
    const product = await this.jobDetailsSerivce.getProductByID(productItem.id);
    const requestPayload = { ...product};

    const retrieveEstimateLinesRequest = this.jobService.getProjectsByJobId(productItem.jobId);

    this.subscriptions$.add(concat(this.productService.deleteProductById(productItem.id), retrieveEstimateLinesRequest).pipe(
      toArray(),
      finalize(() => {
        this.onCancel();
      })
      ).subscribe(
        (data) => {
          this.alertService.showSuccessMessage('Product deleted Successfully.');
          this.dialog.closeAll();

          const treeData = data[1] as SuperDivisionModel[];
          this.setExpandList(treeData, requestPayload);

          this.jobDetailsSerivce.deleteProducts([productItem.id]);
        }
      ))
  }

  setExpandList(data: SuperDivisionModel[], requestPayload: any) {
    const nodeList = data.filter((item) => item.name === requestPayload?.category)
    const sess: { id: number; list: SuperDivisionModel[] } = { id: 0, list: [] };
    const expandList = sessionStorage.getItem('expandList');

    if(expandList) {
      sess.id = JSON.parse(expandList).id;
      const filteredList = JSON.parse(expandList).list.filter((item: ProductTreeNode) => item.name !== nodeList[0].name);
      sess.list = [...filteredList,...nodeList];
    } else {
      sess.id = 0;
      sess.list = [...nodeList];
    }
    sessionStorage.removeItem('scrollPersist');
    sessionStorage.setItem('expandList',JSON.stringify(sess));
  }

  toggleEditProductItem(val: boolean) {
    this.editProductItem = val;
    this.editProduct.emit(val);
    this.calculateRowHeight();
  }

  displayProductSearch() {
    event?.stopPropagation();
    this.activeProductSearch = true;
    this.productEditForm.patchValue({
      sku: ''
    });
    this.onInputChange();
  }

  updateProduct(product: Product) {
    this.activeProductSearch = false;
    this.searchComponent.clearSelection();

    if(this.productItem.unit !== product.sellUnit) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: "Unit Change",
          message: `Unit of measure has changed from ${this.productItem.unit} to ${product.sellUnit}. Please check.`,
          successButtonLabel: ""
        },
        width: '40%',
        disableClose: true,
        //autoFocus: false
      });

      dialogRef.afterClosed().subscribe(() => {
        setTimeout(() => document.getElementById('sku-input')?.focus(), 0);
      });
    }

    this.productFromSearch = product;

    this.productEditForm.patchValue({
      sku: product.productCode,
      description: product.description,
      unit: product.sellUnit
    })

    this.onInputChange();
    setTimeout(() => document.getElementById('sku-input')?.focus(), 0);
  }

  onInputChange() {
    const { sku, description, quantity, unit } = this.productEditForm.value;
    this.toDisableSaveBtn = false;

    if((!quantity && quantity !== 0) || !description || !unit || !sku) {
      this.toDisableSaveBtn = true;
    }
  }

  onUnitInputChange(value: string | null) {
    this.productEditForm.patchValue({
      unit: value
    });
    this.onInputChange();
  }

  onKeyDown(event: KeyboardEvent, productItem: EstimateLineModel) {
    if ((event.key === "Backspace" || event.key === "Delete") && (event.target && (event.target as Element).tagName === "MAT-CARD")) {
      this.onDelete(productItem);
    } else if(event.key === "Escape") {
      this.onCancel();
    } else if(event.key === "Enter") {
      !this.toDisableSaveBtn && this.onSave();
    }
  }

  onFocus(event: FocusEvent) {
    (<HTMLInputElement>event.target).select();
  }

  onUnitValid(value: boolean) {
    this.toDisableSaveBtn = !value;
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
    this.inlineEditService.endEditing();
  }
}
