import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {
  private dataSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  publishNewJobEvent(): void {
    this.dataSubject.next('');
  }

  listenJobEvent(): Observable<string> {
    return this.dataSubject.asObservable();
  }
}
