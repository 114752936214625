import { Injectable } from '@angular/core';
import { SkuMappingModel } from '../models/SkuMappingModel';
import { Observable, catchError, throwError } from 'rxjs';
import { Constants } from '../constants/constants';
import { SkuMappingSearchResult } from '../models/SkuMappingSearchResult';
import { ApiRequestService } from './api-request.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './alert.service';
import { SkuMappingChangeModel } from '../models/skuMappingChangeModel';

@Injectable({
  providedIn: 'root',
})
export class SkuMappingService {
  constructor(
    private apiRequestService: ApiRequestService,
    private alertService: AlertService
  ) {}

  private handleDuplicateSKUResponse<T>(
    observable: Observable<T>,
    solidBuilderSku?: string
  ): Observable<T> {
    return observable.pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.alertService.showErrorMessage(
            `The Solid Builder SKU ${solidBuilderSku} has a current mapping.`
          );
        }
        return throwError(() => error);
      })
    );
  }

  searchByBuilderSkus(
    searchString: string | null
  ): Observable<SkuMappingSearchResult> {
    let url = `${Constants.SKU_MAPPING_API_URL}/Search/SolidBuilderSku`;

    if (searchString) {
      url += `?SearchTerm=${searchString}`;
    }
    return this.apiRequestService.get<SkuMappingSearchResult>(
      url,
      undefined, //No request options
      Constants.SEARCH_MAPPINGS_BY_SKU_ERROR
    );
  }

  searchByProductCode(
    searchString: string | null
  ): Observable<SkuMappingSearchResult> {
    let url = `${Constants.SKU_MAPPING_API_URL}/Search/ProductCode`;

    if (searchString) {
      url += `?SearchTerm=${searchString}`;
    }
    return this.apiRequestService.get<SkuMappingSearchResult>(
      url,
      undefined, //No request options
      Constants.SEARCH_MAPPINGS_BY_SKU_ERROR
    );
  }

  createNewSkuMapping(payload: SkuMappingModel): Observable<SkuMappingModel> {
    const request = this.apiRequestService.post<SkuMappingModel>(
      Constants.SKU_MAPPING_API_URL,
      payload
    );
    return this.handleDuplicateSKUResponse(request, payload.solidBuilderSku);
  }

  updateSkuMapping(
    skuMappingId: number,
    payload: SkuMappingModel
  ): Observable<SkuMappingModel> {
    const request = this.apiRequestService.put<SkuMappingModel>(
      `${Constants.SKU_MAPPING_API_URL}/${skuMappingId}`,
      payload
    );

    return this.handleDuplicateSKUResponse(request, payload.solidBuilderSku);
  }

  deleteSkuMapping(payload: number[]) {
    return this.apiRequestService.delete(
      `${Constants.SKU_MAPPING_API_URL}/Bulk`,
      { body: payload },
      Constants.DELETE_MAPPING_ERROR
    );
  }

  bulkUploadSkuMappings(payload: SkuMappingModel[]) {
    return this.apiRequestService.post(
      `${Constants.SKU_MAPPING_API_URL}/Bulk`,
      payload,
      undefined,
      Constants.ADD_SKUS_ERROR
    );
  }

  uploadSkuMappingCsv(file: File): Observable<SkuMappingChangeModel[]>{
    const fileData = new FormData();
    fileData.append('file', file);
    return this.apiRequestService.post<SkuMappingChangeModel[]>(
      `${Constants.SKU_MAPPING_API_URL}/Upload`,
      fileData,
      undefined,
      Constants.UPLOAD_SKU_MAPPINGS_CSV_ERROR
    );
  }
}