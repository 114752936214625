import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import {AngularMaterialModule} from './core/modules/angular-material/angular-material.module';
import { ErrorInterceptor } from './core/Interceptors/error.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NewJobDialogModule } from './new-job-dialog/new-job-dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingPageModule } from './landing-page/landing-page.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    AngularMaterialModule,
    LandingPageModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NewJobDialogModule,
    MatMenuModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
