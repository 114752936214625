import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, filter, mergeMap } from 'rxjs';
import { AuthService as Auth0Service, GenericError } from '@auth0/auth0-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
  subscriptions:Subscription = new Subscription();
  title = 'PM.SkuMappingTool';

  constructor(private authService: Auth0Service){}

  ngOnInit() {    
    this.subscriptions.add(this.authService.error$.pipe(
      filter((e) => e instanceof GenericError && e.error === 'login_required'),
      mergeMap(() => this.authService.loginWithRedirect())
    ).subscribe())
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }




}
