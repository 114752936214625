import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { catchError, throwError } from 'rxjs';
import { Constants } from '../core/constants/constants';
import { AlertService } from '../core/services/alert.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit{

  showMenu = false;
  constructor(private route: Router, public auth: AuthService, private alertService: AlertService){}

  ngOnInit() {
    this.auth.getLoggedInUser().pipe(
      catchError((error)=>{ //Handling error at component level as handling at service level will cause an infinite loop
        console.log(error, 'AuthService Error');
        this.alertService.showErrorMessage(Constants.GET_USER_ERROR);
        return throwError(()=>error);
      })
    ).subscribe(user => {      
      this.showMenu = user?.['admin']
    })
  }

  navigateTo(path: string) {
    if(this.route.url == path) return;
    this.route.navigate([path]);
  }
  
  logout(): void {
    this.auth.logout();
  }
}
