<mat-card class="subcategory">
    <mat-card-header class="justify-content-between align-items-center sub-cat-header">
        <mat-card-title>
            <span [hidden]="hideCheckbox">
                <mat-checkbox class="mat-icon-rtl-mirror"
                    (change)="checkItems($event.checked, subcategory.id, subcategory)" #subCatCheckbox></mat-checkbox>
            </span>
            <h4 class="sub-cat-name">{{subcategory.name}}</h4>
        </mat-card-title>
        <button *ngIf="!isProductSubcategoryReadonly" mat-icon-button color="accent" (click)="onAddProduct()"
            matTooltip="Add a new product">
            <mat-icon>add_circle</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content class="card-content-align">
        <div class="flex">
            <div class="flex-column flex-grow-1">
                <div *ngIf="subcategory.estimateLines">
                    <mat-grid-list cols="12" rowHeight="50px">
                        <mat-grid-tile class="empty-title" colspan="1"></mat-grid-tile>
                        <mat-grid-tile class="sku-title" colspan="1">
                            <div class="flex-grow-1">
                                <span>SKU</span>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1">
                            <span>Status</span>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="3">
                            <div class="flex-grow-1">
                                <span>Description</span>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="!editProductItem ? 4 : 3">
                            <div class="flex-grow-1">
                                <span>Product Use Description</span>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" *ngIf="!editProductItem; else showQuantityAndUnit">
                            <span>Quantity</span>
                        </mat-grid-tile>
                        <ng-template #showQuantityAndUnit>
                          <mat-grid-tile colspan="1">
                            <span>Quantity</span>
                          </mat-grid-tile>
                          <mat-grid-tile colspan="1">
                            <span class="add-padding">Unit</span>
                          </mat-grid-tile>
                        </ng-template>
                        <mat-grid-tile colspan="1"></mat-grid-tile>
                    </mat-grid-list>

                    <div>
                        <div *ngFor="let item of subcategory.estimateLines">
                            <app-product [productItem]="item"
                                [isProductReadonly]="isProductSubcategoryReadonly" (editProduct)="onEditProductItem($event)"></app-product>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
