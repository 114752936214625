import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CategoryTreeNode } from 'src/app/core/models/CategoryTreeNode';
import { JobSuperDivisionModel } from 'src/app/core/models/JobSuperDivisionModel';
import { ProductTreeNode } from 'src/app/core/models/ProductTreeNode';
import { DeleteService } from 'src/app/core/services/delete.service';
import { JobDetailsService } from 'src/app/core/services/job-details.service';
import { NoteService } from 'src/app/core/services/note.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddProductDialogComponent } from '../add-product-dialog/add-product-dialog.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { ImportSkuDialogComponent } from '../import-sku-dialog/import-sku-dialog.component';

interface LocationState {
  category: string;
}

@Component({
  selector: 'app-job-products-tree',
  templateUrl: './job-products-tree.component.html',
  styleUrls: ['./job-products-tree.component.scss'],
})
export class JobProductsTreeComponent implements OnInit, OnDestroy {
  hideCheckbox = true;
  btnDisable = true;
  selectedList: number[] = [];
  enableBulkDelete = true;
  btnClr = '';
  addedCategory = '';

  dataSourceViewer: CollectionViewer = {
    viewChange: new Observable<ListRange>(),
  };

  sess: { id: number; list: ProductTreeNode[] } = { id: 0, list: [] };

  @Input()
  isJobProductTreeReadonly = false;

  @ViewChild('catCheckBox') catCheckBox: MatCheckbox;
  @ViewChild('bulkDeleteToggle') bulkDeleteToggle: MatSlideToggle;

  subscriptions$: Subscription = new Subscription();

  private _transformer = (node: CategoryTreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      products: node.products,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ProductTreeNode, CategoryTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener<
    CategoryTreeNode,
    ProductTreeNode,
    CategoryTreeNode
  >(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private dialog: MatDialog,
    private jobDetailsSerivce: JobDetailsService,
    private noteService: NoteService,
    private deleteService: DeleteService,
    private router: Router,
    private alertService: AlertService,
    private addProductDialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private cdRef: ChangeDetectorRef
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.addedCategory = (this.location.getState() as LocationState).category;
  }

  private setTreeNodeData(data: JobSuperDivisionModel[]) {
    const superDivisions = data;
    const treeData = superDivisions.map((category: JobSuperDivisionModel) => ({
      name: category.name,
      children: [
        {
          name: category.name,
          children: [],
          products: category,
        },
      ],
      products: category,
    }));
    this.dataSource.data = treeData;
    const firstNode = this.treeControl.dataNodes[0];
    this.treeControl.expand(firstNode);

    this.expandNodes(this.treeControl.dataNodes);
  }

  ngOnInit(): void {
    this.route.params.subscribe((param: Params) => {
      this.sess.id = +param['id'];
    });

    const data = sessionStorage.getItem('expandList');
    const toExpandNodes = data ? JSON.parse(data) : [];

    if (toExpandNodes.id != this.sess.id) {
      sessionStorage.removeItem('expandList');
      sessionStorage.removeItem('scrollPersist');
    }

    this.subscriptions$.add(
      this.jobDetailsSerivce.jobDetailsChangeListener().subscribe({
        next: (data) => {
          this.setTreeNodeData(data);
        },
        error: (error) => {
          console.log(error);
        },
      })
    );

    this.subscriptions$.add(
      this.jobDetailsSerivce.productUpdateListener().subscribe({
        next: (data) => {
          const treeDataSource = this.dataSource.data;

          treeDataSource.forEach(product => {
            product.children[0].products?.assemblyDivisions?.forEach(assemblyDivision => {
              if (assemblyDivision.estimateLines) {
                for (const [index, estimateLine] of assemblyDivision.estimateLines.entries()) {
                  if (estimateLine.id === data.id) {
                    assemblyDivision.estimateLines[index] = data;
                    break;
                  }
                }
              }
            })
            this.cdRef.detectChanges();
          });
        },
        error: (error) => {
          console.log(error);
        },
      })
    );

    this.subscriptions$.add(
      this.deleteService.checkBoxVisibilityChange$.subscribe((value) => {
        this.hideCheckbox = value;
        if (this.catCheckBox) this.catCheckBox.checked = false;
      })
    );

    this.subscriptions$.add(
      this.deleteService.selectedItemsToDelete$.subscribe((data) => {
        this.selectedList = data;
        this.btnDisable = this.selectedList.length > 0 ? false : true;
        this.btnClr = this.selectedList.length > 0 ? 'accent' : '';
      })
    );
  }

  expandNodes(nodes: ProductTreeNode[]) {
    // We maintain two expand lists:
    // - 'expandList' keeps track of the latest expanded nodes.
    // - 'expandListBeforeDeletion' keeps track of an expanded node before a delete operation.
    const nodesToExpand = this.getExpandNodeList();
    if(nodesToExpand.list && nodesToExpand.list.length > 0)
      nodesToExpand.list.forEach((node: ProductTreeNode) => {
        const toExpand = nodes.some((item: ProductTreeNode) => {
          return item.name === node.name || this.addedCategory === item.name;
        });
        if (toExpand) {
          const nodeToExpand = nodes.filter((item: ProductTreeNode) => item.name === node.name);
          this.treeControl.expand(nodeToExpand[0]);
          setTimeout(() => {
            const expandedNodes = document.querySelector('.selected-node');
            expandedNodes && expandedNodes.scrollIntoView({behavior: 'smooth'});
          }, 100)
        }
      });
  }

  getExpandNodeList() {
    const expandListSessStorage =
      sessionStorage.getItem('expandListBeforeOperation') ||
      sessionStorage.getItem('expandList');

    if (expandListSessStorage) {
      sessionStorage.removeItem('expandListBeforeOperation');
    }

    return expandListSessStorage
      ? JSON.parse(expandListSessStorage)
      : [];
  }

  isSelected(node: ProductTreeNode) {
    const nodesToExpand = this.getExpandNodeList();
    return nodesToExpand.list && nodesToExpand.list.length > 0 ? node.name === nodesToExpand.list[nodesToExpand.list.length - 1].name : false;
  }

  toggleExpandIcon(isExpanded: boolean, node: ProductTreeNode) {
    if(isExpanded) {
      this.sess.list = [...this.sess.list, node];
    } else {
      const filterNode = this.sess.list.filter((item) => item.name !== node.name)
      this.sess.list = [...filterNode];
    }
    sessionStorage.setItem('expandList', JSON.stringify(this.sess));
  }

  openNotes() {
    this.noteService.openNote();
  }

  showDeleteOptions() {
    const elements: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[id^="mat-mdc-checkbox"]:checked`
    );
    elements.forEach((item: HTMLInputElement) => {
      item.checked = false;
    });
    this.clearSelection();
    this.enableBulkDelete = !this.enableBulkDelete;
  }

  cancelDelete() {
    this.showDeleteOptions();
    this.bulkDeleteToggle.checked = false;
  }

  clearSelection() {
    this.btnDisable = true;
    this.deleteService.selectedList = [];
    this.deleteService.toggleCheckboxVisibility();
  }

  addProduct() {
    this.addProductDialog.open(AddProductDialogComponent, {
      width: '40%',
      disableClose: true,
      autoFocus: false,
    });
  }

  bulkImportProducts() {
    this.dialog.open(ImportSkuDialogComponent, {
      width: '40%',
      disableClose: true,
      autoFocus: false,
    });
  }

  bulkDelete() {
    if (this.selectedList.length == 0) {
      this.alertService.showErrorMessage('No items selected for delete.');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Confirm Bulk Delete',
        message: `Are you sure you want to delete the selected sku(s)?`,
        successButtonLabel: 'Confirm',
      },
      width: '40%',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Confirmed') {
        this.deleteService.deleteSelectedItems(this.selectedList).subscribe({
          next: () => {
            this.alertService.showSuccessMessage(
              'Sku(s) deleted successfully.'
            );
            this.jobDetailsSerivce.deleteProducts(this.selectedList);
            this.btnDisable = true;
            this.deleteService.clearSelectedList();
          },
        });
      }
    });
  }

  checkItems(check: boolean, node: ProductTreeNode) {
    this.treeControl.expand(node);
    const subCatList = node.products?.assemblyDivisions?.map((item) => item.id);
    this.deleteService.selectSubCatItems$.next({
      subCatList: subCatList,
      toSelect: check,
    });
  }

  hasChild = (_: number, node: ProductTreeNode) => node.expandable;

  ngOnDestroy(): void {
    this.btnDisable = true;
    this.deleteService.selectedList = [];
    if (!this.enableBulkDelete) {
      this.deleteService.toggleCheckboxVisibility();
    }
    this.subscriptions$.unsubscribe();
  }
}
