import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  // eslint-disable-next-line
  transform(items: any, searchText: string, filterOnFields: string[]): [] {
    if (!items || !searchText)
      return items;

    // eslint-disable-next-line
    return items.filter((item: any) => {
      return filterOnFields.some(key => {
        return item[key].toLowerCase().startsWith(searchText.toLowerCase());
      });
    });
  }
}