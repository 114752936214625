import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { AlertService } from '../services/alert.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private alertService: AlertService) { }
  canActivate(): Observable<boolean> {
    return this.auth.getLoggedInUser().pipe(map(user => {
      if (user?.['admin']) {
        return true;
      } else {
        this.alertService.showErrorMessage('Permission Denied. Please check your access and try again.');
        this.router.navigate(['/']);
        return false;
      }
    }))
  }
}