import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-change-table-cell',
  templateUrl: './view-change-table-cell.component.html',
  styleUrls: ['./view-change-table-cell.component.scss']
})
export class ViewChangeTableCellComponent {
  
  @Input()
  exisitngText: string;

  @Input()
  newText: string;

  @Input()
  isNew:boolean;

  @Input()
  isSelected = false;
}
