import { AfterViewInit, Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[appScrollToPosition]'
})

export class ScrollToPositionDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll() {
    const position = Math.floor(
      this.el.nativeElement.scrollTop ||
      this.el.nativeElement.scrollY ||
        0
    );
    const scroll = { scrollX: 0, scrollY: position };
    if (position != 0)
      sessionStorage.setItem('scrollPersist', JSON.stringify(scroll));
  }

  ngAfterViewInit() {
    const scroll = sessionStorage.getItem('scrollPersist');
    const toScroll = scroll ? JSON.parse(scroll) : {};
    setTimeout(() => {
      this.el.nativeElement.scrollTo(toScroll.scrollX, toScroll.scrollY);
    }, 500);
  }
}