import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, concat, finalize, toArray } from 'rxjs';
import { SuperDivisionModel } from 'src/app/core/models/SuperDivisionModel';
import { AlertService } from 'src/app/core/services/alert.service';
import { JobDetailsService } from 'src/app/core/services/job-details.service';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-import-sku-dialog',
  templateUrl: './import-sku-dialog.component.html',
  styleUrls: ['./import-sku-dialog.component.scss'],
})
export class ImportSkuDialogComponent implements OnDestroy {
  file: File;

  form = new FormControl(false, [Validators.requiredTrue]);

  savingChange = false;

  subscriptions = new Subscription();

  constructor(
    private jobService: JobService,
    private jobDetailService: JobDetailsService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<ImportSkuDialogComponent>,
  ) { }

  onUploadFile(file: File) {
    this.file = file;
    this.form.patchValue(true);
  }

  onDeleteFile() {
    this.form.patchValue(false);
  }

  onSave() {
    const { id } = this.jobDetailService.getJobDetail();
    const updateEstimateLineRequest = this.jobService.appendJobSKU(id, this.file);
    const retrieveEstimateLinesRequest = this.jobService.getProjectsByJobId(id);

    this.savingChange = true;
    this.subscriptions.add(
      concat(updateEstimateLineRequest,retrieveEstimateLinesRequest)
        .pipe(
          toArray(),
          finalize(() => {
            this.savingChange = false;
          })
        )
        .subscribe({
          next: (data) => {
            this.dialogRef.close();
            this.alertService.showSuccessMessage('SKUs added Successfully.');
            this.jobDetailService.setjobProductDetails(data[1] as SuperDivisionModel[]);
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
