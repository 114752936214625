import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductSearchResult } from '../models/ProductSearchResult';
import { EstimateLineModel } from '../models/EstimateLineModel';
import { Constants } from '../constants/constants';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private apiRequestService: ApiRequestService) {}

  public updateProductById(productId: number, payload: EstimateLineModel) {
    return this.apiRequestService.put(
      `${Constants.ESTIMATE_LINE_API_URL}/${productId}`,
      { ...payload },
      undefined, //No request options
      Constants.UPDATE_PRODUCT_ERROR
    );
  }

  public deleteProductById(productId: number) {
    return this.apiRequestService.delete(
      `${Constants.ESTIMATE_LINE_API_URL}/${productId}`,
      undefined, //No request options
      Constants.DELETE_PRODUCT_ERROR
    );
  }

  public searchProduct(searchTerm: string,pageSize: number, pageIndex: number ): Observable<ProductSearchResult> {
    return this.apiRequestService.get<ProductSearchResult>(
      `${Constants.PRODUCT_API_URL}/search/all?searchTerm=${searchTerm}&PageSize=${pageSize}&PageNumber=${pageIndex}`,
      undefined, //No request options
      Constants.SEARCH_PRODUCT_ERROR
    );
  }

  public addProduct(payload: EstimateLineModel): Observable<EstimateLineModel> {
    return this.apiRequestService.post<EstimateLineModel>(
      `${Constants.ESTIMATE_LINE_API_URL}`,
      { ...payload },
      undefined, //No request options
      Constants.ADD_PRODUCT_ERROR
    );
  }
}

