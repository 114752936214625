import { AfterContentInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddProductDialogComponent } from '../add-product-dialog/add-product-dialog.component';
import { JobAssemblyDivisionModel } from 'src/app/core/models/JobAssemblyDivisionModel';
import { DeleteService } from 'src/app/core/services/delete.service';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-subcategory',
  templateUrl: './product-subcategory.component.html',
  styleUrls: ['./product-subcategory.component.scss']
})
export class ProductSubcategoryComponent implements OnInit, AfterContentInit, OnDestroy {
  hideCheckbox = true;
  editProductItem = false;

  @Input()
  subcategory: JobAssemblyDivisionModel

  @Input()
  isProductSubcategoryReadonly = false;

  @ViewChild('subCatCheckbox') subCatCheckbox: MatCheckbox;

  subscription$: Subscription = new Subscription();

  constructor(private addProductDialog: MatDialog, private deleteService: DeleteService) { }

  ngOnInit() {
    this.subscription$.add(this.deleteService.checkBoxVisibilityChange$.subscribe(value => {
      this.hideCheckbox = value;
      if(this.subCatCheckbox)
        this.subCatCheckbox.checked = false;
    }));
  }

  ngAfterContentInit() {
    this.subscription$.add(this.deleteService.selectSubCatItems$.subscribe((item) => {
      if(Object.keys(item).length > 0 && this.subCatCheckbox) {
        if(item?.subCatList?.includes(this.subcategory.id)){
          this.subCatCheckbox.checked = item?.toSelect;
        }

        const myEvent: MatCheckboxChange = {
            checked: this.subCatCheckbox.checked,
            source: this.subCatCheckbox,
          };
        this.subCatCheckbox.change.emit(myEvent);
      }
    }));
  }

  onAddProduct() {
    this.addProductDialog.open(
      AddProductDialogComponent, {
      data: this.subcategory,
      width: '40%',
      disableClose: true,
      autoFocus: false
    }
    );
  }

  checkItems(check: boolean, itemId: number, node: JobAssemblyDivisionModel) {
    const skuList = node.estimateLines?.map((item) => item.id);
    this.deleteService.selectSKUItems$.next({skuList: skuList, toSelect: check});
  }

  onEditProductItem(editItem: boolean) {
    this.editProductItem = editItem;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
