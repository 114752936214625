import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subscription, concat, finalize, toArray } from 'rxjs';
import { AssemblyDivisionModel } from 'src/app/core/models/AssemblyDivisionModel';
import { JobAssemblyDivisionModel } from 'src/app/core/models/JobAssemblyDivisionModel';
import { JobModel } from 'src/app/core/models/JobModel';
import { Product } from 'src/app/core/models/Product';
import { SuperDivisionModel } from 'src/app/core/models/SuperDivisionModel';
import { AlertService } from 'src/app/core/services/alert.service';
import { JobDetailsService } from 'src/app/core/services/job-details.service';
import { ProductService } from 'src/app/core/services/product.service';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-add-product-dialog',
  templateUrl: './add-product-dialog.component.html',
  styleUrls: ['./add-product-dialog.component.scss']
})
export class AddProductDialogComponent implements OnInit, OnDestroy {

  isActive = false;
  savingChange = false;
  estimateLineForm: FormGroup;

  jobDetail: JobModel;

  subCategories: AssemblyDivisionModel[] = [];

  subscriptions: Subscription = new Subscription();

  hintMessage = 'Search existing product or create one.';

  skuControl = new FormControl('', [Validators.pattern(/^\d+$/)]);

  addBySearch = false;
  addBySKU = false;
  toDisableSaveBtn = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: JobAssemblyDivisionModel | null,
    private matDialog: MatDialog,
    private jobDetailService: JobDetailsService,
    private jobService: JobService,
    private productService: ProductService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.jobDetail = this.jobDetailService.getJobDetail();
    if (!this.jobDetail) {
      return;
    }

    this.estimateLineForm = this.formBuilder.group({
      id: new FormControl(0, [Validators.required, Validators.pattern(/^\d+$/)]),
      jobId: new FormControl(this.jobDetail.id, [Validators.required, Validators.pattern(/^\d+$/)]),
      sku: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      quantity: new FormControl('', [Validators.required]),
      unit: new FormControl('', Validators.required),
      sortCode: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
      productCode: new FormControl(''),
      assemblyDivisionId: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
      productUseDescription: new FormControl('')
    });

    if(this.data){
      this.estimateLineForm.patchValue({sortCode: this.data.sortCode, assemblyDivisionId: this.data.id});
    }else{
      this.estimateLineForm.addControl('category', new FormControl('', Validators.required));
      this.estimateLineForm.addControl('subcategory', new FormControl('', Validators.required));
    }

    this.estimateLineForm.disable();

    this.subscriptions.add(this.skuControl.valueChanges.subscribe(
      (value) => {
        if (this.skuControl.value && this.skuControl.valid) {
          this.addBySKU = true
          this.estimateLineForm.removeControl('productCode');
          this.estimateLineForm.patchValue({sku:value});
          this.estimateLineForm.enable();
        } else {
          this.addBySKU = false;
          this.estimateLineForm.addControl('productCode',new FormControl(''));
          this.estimateLineForm.reset();
          this.estimateLineForm.patchValue({id:0, jobId:this.jobDetail.id});
          this.estimateLineForm.disable();
        }
      }
    ));
  }

  onSelectCategory(selectedCategory: SuperDivisionModel) {
    this.subCategories = selectedCategory.assemblyDivisions ? selectedCategory.assemblyDivisions : [];

    if (this.estimateLineForm.get('category')?.dirty) {
      this.estimateLineForm.patchValue({ assemblyDivisionId: null, subcategory: null });
    }
  }

  onSelectSubcategory(selectedSubcategory: AssemblyDivisionModel) {
    this.estimateLineForm.patchValue({ assemblyDivisionId: selectedSubcategory.id, sortCode: selectedSubcategory.sortCode });
  }

  onProductChange(product: Product) {
    this.addBySearch = true;

    this.estimateLineForm.enable();
    this.estimateLineForm.patchValue({
      sku: product.productCode,
      description: product.description,
      unit: product.sellUnit,
      productCode: product.productCode
    })
  }

  onSave() {
    if (!this.estimateLineForm.valid) {
      this.alertService.showErrorMessage('Please complete all fields marked with *.');
      return;
    }

    const payload = {...this.estimateLineForm.value};
    delete payload.category;
    delete payload.subcategory

    const addEstimateLineRequest = this.productService.addProduct(payload);
    const retrieveEstimateLinesRequest = this.jobService.getProjectsByJobId(this.jobDetail.id);


    this.estimateLineForm.disable();
    this.savingChange = true;
    this.subscriptions.add(
      concat(addEstimateLineRequest,retrieveEstimateLinesRequest).pipe(
        toArray(),
        finalize(() => {
          this.savingChange = false;
          this.estimateLineForm.enable();
        })).subscribe(
             (data) => {
              this.alertService.showSuccessMessage('Product added Successfully.');
              this.matDialog.closeAll();
              this.jobDetailService.setjobProductDetails(data[1] as SuperDivisionModel[]);
            }
        ));
  }

  resetForm(){
    console.log('reset form');
    this.estimateLineForm.reset();
    this.skuControl.reset();
    this.estimateLineForm.patchValue({id:0, jobId:this.jobDetail.id});
    this.addBySKU = false;
    this.addBySearch = false;

  }

  onUnitInputChange(value: string | null) {
    this.estimateLineForm.patchValue({
      unit: value
    });
    this.onInputChange();
  }

  onInputChange() {
    const { sku, description, quantity, unit } = this.estimateLineForm.value;
    this.toDisableSaveBtn = false;

    if((!quantity && quantity !== 0) || !description || !unit || !sku) {
      this.toDisableSaveBtn = true;
    }
  }

  onUnitValid(value: boolean) {
    this.toDisableSaveBtn = !value;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }



}
