<app-loading></app-loading>
<div class="dialog-header">
  <h3>Add New Job</h3>
  <button mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="dialog-content-height">
  <mat-stepper labelPosition="bottom" linear="true" class="import-steps">
    <mat-step [stepControl]="firstFormGroup" editable="false">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Find <br> Job Number</ng-template>
        <div class="content-height flex">
          <app-search-job class="flex-grow" (jobSearchEvent)="onJobNumberChosen($event)"></app-search-job>
        </div>
        <div class="flex justify-content-end">
          <button mat-raised-button color="primary" [disabled]="checkFirstStepValidity()" matStepperNext
            class="margin-right-10">Next</button>
          <button mat-stroked-button color="primary" matStepperNext (click)="cancel()">Cancel</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" editable="false">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Upload <br> Solid Builder file</ng-template>
        <div class="content-height flex">
          <app-upload-file class="flex-grow" (uploadFileEvent)="onUploadSolidBuilderFile($event)"
            (fileDeleteEvent)="onDeleteSolidBuilderFile()"></app-upload-file>
        </div>
        <div class="flex justify-content-end">
          <button mat-raised-button color="primary" [disabled]="!secondFormGroup.valid" matStepperNext
            class="margin-right-10">Next</button>
          <button mat-stroked-button color="primary" matStepperNext (click)="cancel()">Cancel</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup" editable="false">
      <ng-template matStepLabel>Upload <br> Special Timber Sheet</ng-template>
      <div class="content-height flex">
        <app-upload-file class="flex-grow" (uploadFileEvent)="onUploadSpecialTimberSheet($event)"
          (fileDeleteEvent)="onDeleteSpecialTimberSheet()"></app-upload-file>
      </div>
      <div class="flex justify-content-end">
        <button mat-raised-button color="primary" [disabled]="!thirdFormGroup.valid" matStepperNext (click)="submit()"
          class="margin-right-10">
          <span>Finish</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext (click)="cancel()">Cancel</button>
      </div>
      <div class="flex justify-content-end">

      </div>
    </mat-step>

  </mat-stepper>
</div>