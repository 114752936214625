<section class="container font-14" tabindex="0">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="height-100 flex justify-content-end align-items-center">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="selection.toggle(row)"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </div>
      </td>
    </ng-container>

    <!-- SKU mapping details -->
    <ng-container matColumnDef="solidBuilderSku">
      <th mat-header-cell *matHeaderCellDef><b>SB SKU</b></th>
      <td mat-cell *matCellDef="let row">
        <app-view-change-table-cell
          [exisitngText]="row.existing ? row.existing.solidBuilderSku : null"
          [newText]="row.new.solidBuilderSku"
          [isNew]="row.existing? false: true"
          [isSelected]="expandedRows.isSelected(row)"
        ></app-view-change-table-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="solidBuilderDescription">
      <th mat-header-cell *matHeaderCellDef><b>SB Description</b></th>
      <td
        mat-cell
        *matCellDef="let row"
        class="no-wrap"
        [class.wrap]="expandedRows.isSelected(row)"
      >
        <app-view-change-table-cell
          [exisitngText]="
            row.existing ? row.existing.solidBuilderDescription : null
          "
          [newText]="row.new.solidBuilderDescription"
          [isNew]="row.existing? false: true"
          [isSelected]="expandedRows.isSelected(row)"
        ></app-view-change-table-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="productCode">
      <th mat-header-cell *matHeaderCellDef><b>ACE SKU</b></th>
      <td mat-cell *matCellDef="let row">
        <app-view-change-table-cell
          [exisitngText]="row.existing ? row.existing.productCode : null"
          [newText]="row.new.productCode"
          [isNew]="row.existing? false: true"
          [isSelected]="expandedRows.isSelected(row)"
        ></app-view-change-table-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef><b>Description</b></th>
      <td
        mat-cell
        *matCellDef="let row"
        class="no-wrap"
        [class.wrap]="expandedRows.isSelected(row)"
      >
        <app-view-change-table-cell
          [exisitngText]="
            row.existing ? row.existing.product.description : null
          "
          [newText]="row.new.product.description"
          [isNew]="row.existing? false: true"
          [isSelected]="expandedRows.isSelected(row)"
        ></app-view-change-table-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="productUseDescription">
      <th mat-header-cell *matHeaderCellDef><b>Product Use Description</b></th>
      <td
        mat-cell
        *matCellDef="let row"
        class="no-wrap"
        [class.wrap]="expandedRows.isSelected(row)"
      >
        <app-view-change-table-cell
          [exisitngText]="
            row.existing ? row.existing.productUseDescription : null
          "
          [newText]="row.new.productUseDescription"
          [isNew]="row.existing? false: true"
          [isSelected]="expandedRows.isSelected(row)"
        ></app-view-change-table-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="sellUnit">
      <th mat-header-cell *matHeaderCellDef><b>Unit</b></th>
      <td mat-cell *matCellDef="let row">
        <app-view-change-table-cell
          [exisitngText]="row.existing ? row.existing.product.sellUnit : null"
          [newText]="row.new.product.sellUnit"
          [isNew]="row.existing? false: true"
          [isSelected]="expandedRows.isSelected(row)"
        ></app-view-change-table-cell>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="height-100 flex align-items-center">
          <mat-icon
            *ngIf="row.existing; else newIcon"
            fontIcon="adjust"
            class="yellow"
            matTooltip="Modified"
          ></mat-icon>
          <ng-template #newIcon>
            <mat-icon
              fontIcon="add_circle_outline"
              class="green"
              matTooltip="New"
            ></mat-icon>
          </ng-template>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="expandedRows.toggle(row)"
      class="row-item"
    ></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons>
  </mat-paginator>
</section>
