<h1 mat-dialog-title class="card-title">Add product</h1>

<div class="search-container">
  <div class="hint">{{hintMessage}}</div>
  <div class="flex">
    <app-product-search [isActive]="true" (productChangedEvent)="onProductChange($event)" (clearEvent)="resetForm()"
      class="product-search" [ngClass]="{'full-width':addBySearch, 'no-display': addBySKU}"></app-product-search>
    <mat-form-field appearance="outline" class="flex-grow-1" subscriptSizing="dynamic"
      [ngClass]="{'no-display':addBySearch}">
      <mat-label>
        SKU
      </mat-label>
      <input matInput [formControl]="skuControl" type="text">
      <div matSuffix>
        <button matSuffix mat-icon-button (click)="resetForm()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </mat-form-field>
  </div>

</div>
<div mat-dialog-content class="p-t-20">
  <form [formGroup]="estimateLineForm" class="flex-column">
    <app-category-selector *ngIf="estimateLineForm.controls['category']" formControlName="category"
      (selectCategory)="onSelectCategory($event)"></app-category-selector>

    <app-subcategory-selector *ngIf="estimateLineForm.controls['subcategory']" formControlName="subcategory"
      [subcategories]="subCategories" (selectSubcategory)="onSelectSubcategory($event)"></app-subcategory-selector>

    <mat-form-field appearance="outline" class="flex-grow-1">
      <mat-label>
        SKU
      </mat-label>
      <input matInput formControlName="sku" type="number" required readonly>
    </mat-form-field>
    <mat-form-field appearance="outline" class="flex-grow-1">
      <mat-label>
        Description
      </mat-label>
      <textarea matInput formControlName="description" type="text" rows="3" required [readonly]="addBySearch"
        class="max-height-textarea"></textarea>
    </mat-form-field>
    <mat-form-field appearance="outline" class="flex-grow-1">
      <mat-label>
        Product Use Description
      </mat-label>
      <textarea matInput formControlName="productUseDescription" type="text" rows="3"
        class="max-height-textarea"></textarea>
    </mat-form-field>
    <ng-template #normalUnit>
      <mat-form-field appearance="outline" class="flex-grow-1">
        <mat-label>
          Quantity
        </mat-label>
        <input matInput formControlName="quantity" type="number" required>
        <span matTextSuffix>{{estimateLineForm.get('unit')?.value}}</span>
      </mat-form-field>
    </ng-template>
    <div class="flex" *ngIf="addBySKU; else normalUnit">
      <mat-form-field appearance="outline" class="quantity-input">
        <mat-label>
          Quantity
        </mat-label>
        <input matInput formControlName="quantity" type="number" required>
      </mat-form-field>
      <app-unit-selector formControlName="unit" class="unit-input" (unitChangeEvent)="onUnitInputChange($event)" (unitValid)="onUnitValid($event)"></app-unit-selector>
    </div>
  </form>

</div>
<div mat-dialog-actions class="p-x-24 justify-content-between footer">
  <div>
    <button mat-raised-button color="primary" [disabled]="this.toDisableSaveBtn || !estimateLineForm.valid" (click)="onSave()">
      <span *ngIf="!savingChange; else savingChangeSpinner">Save</span>
      <ng-template #savingChangeSpinner>
        <mat-spinner mode="indeterminate" diameter="30" color="accent"></mat-spinner>
      </ng-template>
    </button>
    <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
  </div>

</div>
