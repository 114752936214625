import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fileImport } from '../models/FileImportModel';
import { JobModelSearchResponseModel } from '../models/JobModelSearchResponseModel';
import { JobSuperDivisionModel } from '../models/JobSuperDivisionModel';
import { JobSearchResponseModel } from '../models/JobSearchResponseModel';
import { JobDetailsModel } from '../models/JobDetailsModel';
import { Constants } from '../constants/constants';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(private apiRequestSerivce: ApiRequestService) {}

  searchMyJobs(
    searchString: string | null,
    pageIndex: number,
    pageSize: number
  ): Observable<JobSearchResponseModel> {
    let url = `${Constants.JOB_API_URL}/Search/Own?PageSize=${pageSize}&PageNumber=${pageIndex}`;

    if (searchString) {
      url += `&searchTerm=${searchString}`;
    }
    return this.apiRequestSerivce.get<JobSearchResponseModel>(
      url,
      undefined, //No request options
      Constants.SEARCH_JOB_ERROR
    );
  }

  searchAllImportedJobs(
    searchString: string | null = null,
    pageIndex: number,
    pageSize: number
  ): Observable<JobModelSearchResponseModel> {
    let url = `${Constants.JOB_API_URL}/search/imported?pageSize=${pageSize}&pageNumber=${pageIndex}`;

    if (searchString) {
      url += `&searchTerm=${searchString}`;
    }
    return this.apiRequestSerivce.get<JobModelSearchResponseModel>(
      url,
      undefined, //No request options
      Constants.SEARCH_JOB_ERROR
    );
  }

  createNewJob(id: number, files: fileImport) {
    const filesData = new FormData();
    filesData.append('solidBuilderFile', files.solidBuilderFile);
    filesData.append('specialTimberFile', files.specialTimberFile);

    return this.apiRequestSerivce.post(
      `${Constants.JOB_API_URL}/${id}/Import`,
      filesData,
      undefined, //No request options
      Constants.CREATE_JOB_ERROR
    );
  }

  searchBuildItJobs(
    jobNumber: string | null
  ): Observable<JobModelSearchResponseModel> {
    return this.apiRequestSerivce.get<JobModelSearchResponseModel>(
      `${Constants.JOB_API_URL}/Search/All?SearchTerm=${jobNumber}&PageNumber=0`,
      undefined, //No request options
      Constants.SEARCH_JOB_ERROR
    );
  }

  getProjectsByJobId(id: number): Observable<JobSuperDivisionModel[]> {
    return this.apiRequestSerivce.get<JobSuperDivisionModel[]>(
      `${Constants.JOB_API_URL}/${id}/EstimateLines`
    );
  }

  getDetailsByJobId(id: number): Observable<JobDetailsModel> {
    return this.apiRequestSerivce.get<JobDetailsModel>(
      `${Constants.JOB_API_URL}/${id}/Details`,
      undefined, //No request options
      Constants.LOAD_JOB_ERROR
    );
  }

  downloadJobById(id: number): Observable<string> {
    return this.apiRequestSerivce.get(
      `${Constants.JOB_API_URL}/${id}/Export`,
      { responseType: 'text' },
      Constants.DOWNLOAD_JOB_ERROR
    );
  }

  reassignJobById(id: number) {
    return this.apiRequestSerivce.post(
      `${Constants.JOB_API_URL}/${id}/ReassignToSelf`,
      {},
      undefined, //No request options
      Constants.REASSIGN_JOB_ERROR
    );
  }

  appendJobSKU(id: number, file: File) {
    const fileData = new FormData();
    fileData.append('file', file);
    return this.apiRequestSerivce.post(
      `${Constants.JOB_API_URL}/${id}/Append`,
      fileData,
      undefined, //No request options
      Constants.IMPORT_SKUS_ERROR
    );
  }
}
