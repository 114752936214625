import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, concat, finalize, toArray } from 'rxjs';
import { JobDetailsModel } from 'src/app/core/models/JobDetailsModel';
import { AlertService } from 'src/app/core/services/alert.service';
import { JobNoteService } from 'src/app/core/services/job-note.service';
import { JobService } from 'src/app/core/services/job.service';
import { NoteService } from 'src/app/core/services/note.service';

@Component({
  selector: 'app-new-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.scss']
})
export class NewNoteComponent implements OnDestroy {

  @Input()
  jobId: number;

  @Input()
  posIndex: number;

  @Output()
  newNoteEvent = new EventEmitter<boolean>();

  noteFormControl: FormControl = new FormControl('');

  subscriptions: Subscription = new Subscription();

  constructor(
    private jobNoteService: JobNoteService,
    private noteService: NoteService,
    private jobService: JobService,
    private alertService: AlertService
  ) { }

  onAddNewNote() {
    if (!this.noteFormControl.valid) {
      return;
    }

    const addJobRequest = this.jobNoteService.addJobNote({
      id: 0,
      jobId: this.jobId,
      superDivisionId: null,
      estimateLineId: null,
      heading: '',
      note: this.noteFormControl.value,
      reportable: true,
      index: this.posIndex
    });

    const retrieveNotesRequest = this.jobService.getDetailsByJobId(this.jobId);

    this.subscriptions.add(concat(addJobRequest, retrieveNotesRequest).pipe(
      toArray(),
      finalize(() => { this.newNoteEvent.emit(false) }),
      ).subscribe(
        result => {
          const jobDetails = result[1] as JobDetailsModel;
          this.noteService.setNoteData(jobDetails.notes)
          this.noteFormControl.reset();
        }
      ))
  }

  onCancel() {
    this.newNoteEvent.emit(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
