import { Pipe, PipeTransform } from '@angular/core';
import { JobModel } from '../models/JobModel';

@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {

  transform(job: JobModel): string {

    let streetString = '';
    const suburbString = job.suburb ? job.suburb : '';

    if (job.streetName) {
      streetString = `${job.streetNumber ? job.streetNumber : ''} ${job.streetName ? job.streetName : ''}`
    }

    if (!streetString) {
      return suburbString;
    }

    return `${streetString}, ${job.suburb}`;
  }

}
