import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { JobNotePayloadModel } from 'src/app/core/models/JobNotePayloadModel';
import { JobNoteService } from 'src/app/core/services/job-note.service';
import { NoteService } from 'src/app/core/services/note.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit, OnDestroy{

  @ViewChild('noteInputRef', { static: false }) noteInputRef: ElementRef<HTMLTextAreaElement>;

  @Input()
  note: JobNotePayloadModel;

  @Input()
  isNoteReadOnly = false;

  @Output()
  editNoteEvent = new EventEmitter<boolean>();

  readonly noteString = new Subject<string>();

  noteFormControl: FormControl;
  isEditing = false;

  subscriptions: Subscription = new Subscription();

  constructor(
    private jobNoteService: JobNoteService,
    private noteService: NoteService
  ) { }

  ngOnInit(): void {
    this.noteFormControl = new FormControl(this.note.note);

    this.subscriptions.add(this.noteString.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(noteString =>this.jobNoteService.updateJobNote(this.note.id, { ...this.note, note: noteString })
      )
    ).subscribe(
      () => this.noteService.updateNote(this.note.id, this.noteFormControl.value)
    ))
  }

  onNoteStringChange() {
    this.noteString.next(this.noteFormControl.value ? this.noteFormControl.value : '');
  }

  onClick() {
    if(this.isNoteReadOnly){
      return;
    }
    
    this.isEditing = true;
    this.editNoteEvent.emit(true);
    setTimeout(() => {
      if (this.noteInputRef) {
        this.noteInputRef.nativeElement.focus();
      }
    });
  }

  onBlur() {
    this.isEditing = false;
    this.editNoteEvent.emit(false);
  }

  onDelete() {
    this.subscriptions.add(this.jobNoteService.deleteJobNote(this.note.id).subscribe(
      () => {
        this.noteService.deleteNote(this.note.id);
      }
    ))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
