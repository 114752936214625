import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobDetailsService } from 'src/app/core/services/job-details.service';
import { NewJobDialogComponent } from 'src/app/new-job-dialog/new-job-dialog/new-job-dialog.component';
import { ProductService } from 'src/app/core/services/product.service';
import { EstimateLineModel } from 'src/app/core/models/EstimateLineModel';
import { Subscription, concat, finalize, toArray } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { SuperDivisionModel } from 'src/app/core/models/SuperDivisionModel';
import { AssemblyDivisionModel } from 'src/app/core/models/AssemblyDivisionModel';
import { JobService } from 'src/app/core/services/job.service';
import { ProductTreeNode } from 'src/app/core/models/ProductTreeNode';
import { FormModel } from 'src/app/core/models/FormModel';

@Component({
  selector: 'app-product-editing-dialog',
  templateUrl: './product-editing-dialog.component.html',
  styleUrls: ['./product-editing-dialog.component.scss']
})
export class ProductEditingDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;

  activeProductSearch = false;

  savingChange = false;

  subCategories: AssemblyDivisionModel[] = [];

  subscription: Subscription = new Subscription();

  sku: string | undefined | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: EstimateLineModel,
    private dialogRef: MatDialogRef<NewJobDialogComponent>,
    private formBuilder: FormBuilder,
    private jobDetailsSerivce: JobDetailsService,
    private jobService: JobService,
    private productService: ProductService,
    private alertService: AlertService) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      sku: [''],
      description: [''],
      quantity: [''],
      unit: [''],
      productUseDescription: [''],
      productCode: [''],
      assemblyDivisionId: [''],
      sortCode: ['']
    });

    const product = await this.jobDetailsSerivce.getProductByID(this.data.id);
    this.sku = this.data.sku;

    if (product) {
      this.form.patchValue({
        category: product.category,
        subcategory: product.subcategory,
        sku: this.data.sku,
        description: this.data.description,
        quantity: this.data.quantity,
        unit: this.data.unit,
        productUseDescription: this.data.productUseDescription,
        productCode: this.data.productCode,
        assemblyDivisionId: this.data.assemblyDivisionId,
        sortCode: this.data.sortCode
      });
    }
  }

  onSave() {
    if (!this.form.valid) {
      this.alertService.showErrorMessage('Please complete all fields marked with *.');
      return;
    }

    this.form.disable();
    this.savingChange = true;
    const { sku, ...otherFields } = this.form.value;

    const requestPayload = { ...this.data, ...otherFields, sku: `${sku}` };

    const updateEstimateLineRequest = this.productService.updateProductById(this.data.id, requestPayload);
    const retrieveEstimateLinesRequest = this.jobService.getProjectsByJobId(this.data.jobId);

    this.subscription.add(concat(updateEstimateLineRequest, retrieveEstimateLinesRequest).pipe(
      toArray(),
      finalize(() => {
        this.savingChange = false;
        this.form.enable();
      }),
      ).subscribe(
        (data) => {
          this.alertService.showSuccessMessage('Product updated Successfully.');
          this.dialogRef.close();

          const treeData = data[1] as SuperDivisionModel[];
          this.setExpandList(treeData, requestPayload);

          this.jobDetailsSerivce.setjobProductDetails(data[1] as SuperDivisionModel[]);
        }
      ))
  }

  onSelectCategory(selectedCategory: SuperDivisionModel) {
    this.subCategories = selectedCategory.assemblyDivisions ? selectedCategory.assemblyDivisions.filter(subcategory => !subcategory.deleted) : [];

    if (this.form.get('category')?.dirty) {
      this.form.patchValue({ assemblyDivisionId: null, subcategory: null });
    }
  }

  onSelectSubcategory(selectedSubcategory: AssemblyDivisionModel) {
    this.form.patchValue({ assemblyDivisionId: selectedSubcategory.id, sortCode: selectedSubcategory.sortCode });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setExpandList(data: SuperDivisionModel[], requestPayload: FormModel) {
    const nodeList = data.filter((item) => item.name === requestPayload.category)
    const sess: { id: number; list: SuperDivisionModel[] } = { id: 0, list: [] };
    const expandList = sessionStorage.getItem('expandList');

    if(expandList) {
      sess.id = JSON.parse(expandList).id;
      const filteredList = JSON.parse(expandList).list.filter((item: ProductTreeNode) => item.name !== nodeList[0].name);
      sess.list = [...filteredList,...nodeList];
    } else {
      sess.id = 0;
      sess.list = [...nodeList];
    }
    sessionStorage.removeItem('scrollPersist');
    sessionStorage.setItem('expandList',JSON.stringify(sess));
  }

}
