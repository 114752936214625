import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private searchSubject = new BehaviorSubject<string>('');
  private clearSearchSubject = new BehaviorSubject<boolean>(false);

  searchString: Observable<string> = this.searchSubject.asObservable();
  clearSearchString: Observable<boolean> = this.clearSearchSubject.asObservable();

  setSearchString(value: string) {
    this.searchSubject.next(value);
  }

  clearValue() {
    this.clearSearchSubject.next(true);
  }
}
