import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, concat, finalize, toArray } from 'rxjs';
import { JobDetailsModel } from 'src/app/core/models/JobDetailsModel';
import { JobNoteModel } from 'src/app/core/models/JobNoteModel';
import { AlertService } from 'src/app/core/services/alert.service';
import { JobNoteService } from 'src/app/core/services/job-note.service';
import { JobService } from 'src/app/core/services/job.service';
import { NoteService } from 'src/app/core/services/note.service';

@Component({
  selector: 'app-note-panel',
  templateUrl: './note-panel.component.html',
  styleUrls: ['./note-panel.component.scss']
})
export class NotePanelComponent implements OnInit {
  @Input()
  jobId: number;

  @Input()
  readOnly: boolean;

  notes: JobNoteModel[];
  rearrangedNotes: JobNoteModel[]=[];
  rearrangeNote = false;
  editNote = false;
  subscriptions = new Subscription();
  newNoteIndex = -1;

  @Output() closeNote = new EventEmitter();

  constructor(
    private noteService: NoteService,
    private jobNoteService: JobNoteService,
    private alertService: AlertService,
    private jobService: JobService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.noteService.listenNoteDataUpdateEvent().subscribe(
        {
          next: notes => {
            this.notes = notes;
          },
          error: error => {
            console.log(error);
          }
        }
      )
    );
  }

  onAddNote(index: number) {
    if (index < 0) {
      return;
    }
    this.newNoteIndex = index;
  }

  onNewNoteActionComplete() {
    this.newNoteIndex = -1;
  }

  onRearrangeNote() {
    this.rearrangeNote = true;
  }

  onSaveNotesArrangement() {
    this.rearrangeNote = false;
    this.notes.map((note,index) =>{
      if(note.index !== index){
        note.index = index;
        this.rearrangedNotes.push(note);
      }
    })

    const rearrangeRequest = this.jobNoteService.rearrangeNotes([...this.rearrangedNotes]);
    const retrieveNotesRequest = this.jobService.getDetailsByJobId(this.jobId);

    this.subscriptions.add(concat(rearrangeRequest,retrieveNotesRequest).pipe(
      toArray(),
      finalize(()=>this.rearrangedNotes=[])
      ).subscribe(
        result => {
          const jobDetails = result[1] as JobDetailsModel;
          this.noteService.setNoteData(jobDetails.notes)
        }
      ))
  }

  onToggleNoteEdit(event: boolean) {
    this.editNote = event;
  }

  onCloseNote() {
    this.noteService.closeNote();
    this.closeNote.emit();
  }

  dropNote(event: CdkDragDrop<JobNoteModel[]>) {
    moveItemInArray(this.notes, event.previousIndex, event.currentIndex);
  }

}
