import { environment } from 'src/environments/environment';

export class Constants {
  static readonly ESTIMATE_LINE_API_URL = `${environment.apiUrl}/EstimateLine`;
  static readonly SUPER_DIVISION_API_URL = `${environment.apiUrl}/SuperDivisions`;
  static readonly JOB_NOTE_API_URL = `${environment.apiUrl}/JobNote`;
  static readonly JOB_API_URL = `${environment.apiUrl}/Jobs`;
  static readonly PRODUCT_API_URL = `${environment.apiUrl}/products`;
  static readonly SKU_MAPPING_API_URL = `${environment.apiUrl}/SkuMappings`;
  static readonly UNITS: string[] = [
    'LN',
    'EA',
    'BL',
    'LM',
    'ST',
    'SH',
    'MTQ',
    'MTK',
    'PALL',
    'HUR',
    'BX',
    'BAG',
    'LGTH',
    'BG',
    'PR',
    'PK',
    'PA',
    'RO',
    'SET',
    'PCE',
    'EACH',
    'CT',
    'BD',
    'BJ',
    'CQ',
    'PL',
    'PKT',
    'LTR',
    'DR',
    'BO',
    'TNE',
    'KG',
    'CA',
    'CL',
    'CTN',
    'TN',
    'RL',
    'TU',
    'MTR',
    'ROLL',
    'PACK',
    'BOX',
    'PAIR',
    'M3',
    'BE',
    'HA',
  ];
  static readonly IMPORT_SKUS_ERROR =
    'Error importing the SKUs. Please try again.';
  static readonly REASSIGN_JOB_ERROR =
    'Error reassigning Job. Please try again.';
  static readonly DOWNLOAD_JOB_ERROR =
    'Error downloading the job. Please try again.';
  static readonly GET_USER_ERROR = 'Error getting user id. Please try again.';
  static readonly LOAD_JOB_ERROR = 'Error loading job. Please try again.';
  static readonly SEARCH_JOB_ERROR = 'Error searching job. Please try again.';
  static readonly CREATE_JOB_ERROR =
    'Error creating the Job. Please try again.';
  static readonly GET_CATEGORIES_ERROR =
    'Error getting categories. Please try again.';
  static readonly UPDATE_NOTE_ERROR = 'Error updating notes. Please try again.';
  static readonly ADD_NOTE_ERROR = 'Error adding note. Please try again.';
  static readonly REARRANGE_NOTES_ERROR =
    'Error rearranging notes. Please try again.';
  static readonly DELETE_NOTE_ERROR = 'Error deleting notes. Please try again.';
  static readonly UPDATE_PRODUCT_ERROR =
    'Error updating product. Please try again.';
  static readonly DELETE_PRODUCT_ERROR =
    'Error deleting product. Please try again.';
  static readonly SEARCH_PRODUCT_ERROR =
    'Error searching product. Please try again.';
  static readonly ADD_PRODUCT_ERROR = 'Error adding product. Please try again.';
  static readonly SEARCH_MAPPINGS_BY_SKU_ERROR =
    'Error searching mappings by SKU. Please try again.';
  static readonly DELETE_MAPPING_ERROR =
    'Error deleting sku mappings. Please try again.';
  static readonly DELETE_SKUS_ERROR =
    'Error deleting sku(s). Please try again.';
  static readonly ADD_SKUS_ERROR = 'Error adding sku(s). Please try again.';
  static readonly UPLOAD_SKU_MAPPINGS_CSV_ERROR = 'Error uploading csv file. Please try again.';
  static readonly MAPPING_UPDATED = 'Mapping updated';
  static readonly MAPPING_ADDED = 'Mapping added';
  

}
