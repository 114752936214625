<div *ngIf="!isNew;else SingleTextDisplay" class="flex flex-column height-100">
    <div class="text-overflow" [class.invisible]="exisitngText===newText || !exisitngText">
      <span
        [matTooltip]="exisitngText"
        matTooltipPosition="above"
        [matTooltipPositionAtOrigin]="true"
      >
        <s>
          {{ exisitngText===newText? '': exisitngText }}
        </s>
        <br />
      </span>
    </div>
    <div class="text-overflow">
      <span
        [matTooltip]="newText"
        matTooltipPosition="below"
        [matTooltipPositionAtOrigin]="true"
      >
        {{ newText }}
      </span>
    </div>
  </div>

  <ng-template #SingleTextDisplay> 
    <div [ngClass]="{'height-100':isSelected,'flex-column':isSelected,'text-overflow':true}">
        <span
          [matTooltip]="newText"
          matTooltipPosition="below"
          [matTooltipPositionAtOrigin]="true"
        >
          {{ newText }}
        </span>
      </div>

  </ng-template>
