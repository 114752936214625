import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(bytes: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    
    const base = 1024;
    const decimalPlaces = 2;
    const sizeUnits = ['Bytes', 'KB', 'MB'];
    const unitIndex = Math.floor(Math.log(bytes) / Math.log(base));
    const transformedValue = (bytes / (base ** unitIndex)).toFixed(decimalPlaces);
    
    return `${transformedValue} ${sizeUnits[unitIndex]}`;
  }
  

}
