import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NewJobDialogComponent } from '../new-job-dialog/new-job-dialog/new-job-dialog.component';
import { LinkModel } from '../core/models/LinkModel';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  navLinks: LinkModel[] = [];
  activeLinkIndex = -1;

  constructor(private router: Router, public dialog: MatDialog) {
    this.navLinks = [
      {
        label: 'My Jobs',
        link: '/myJobs',
        index: 0
      }, {
        label: 'All Jobs',
        link: '/allJobs',
        index: 1
      },
    ];
  }
  ngOnInit(): void {
    this.router.events.subscribe(() => {
      const tab = this.navLinks.find(tab => tab.link === '.' + this.router.url)
      if (tab) {
        this.activeLinkIndex = this.navLinks.indexOf(tab);
      }
    });
  }
  openDialog() {
    this.dialog.open(NewJobDialogComponent, {
      disableClose: true,
      width: "50%",
      autoFocus: false
    });
  }

}
