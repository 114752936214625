import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericSearchComponent } from './generic-search/generic-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../core/modules/angular-material/angular-material.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ProductSubcategoryComponent } from './product-subcategory/product-subcategory.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ProductComponent } from './product/product.component';
import { ProductEditingDialogComponent } from './product-editing-dialog/product-editing-dialog.component';
import { JobProductsTreeComponent } from './job-products-tree/job-products-tree.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { LoadingComponent } from './loading/loading.component';
import { AddProductDialogComponent } from './add-product-dialog/add-product-dialog.component';
import { NoteComponent } from './note/note.component';
import { NewNoteComponent } from './new-note/new-note.component';
import { LocationPipe } from '../core/pipes/location.pipe';
import { NotePanelComponent } from './note-panel/note-panel.component';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { SubcategorySelectorComponent } from './subcategory-selector/subcategory-selector.component';
import { UnitSelectorComponent } from './unit-selector/unit-selector.component';
import { SkuMappingItemComponent } from './sku-mapping-item/sku-mapping-item.component';
import { FilterPipe } from '../core/pipes/filter.pipe';
import { SkuMappingDialogComponent } from './sku-mapping-dialog/sku-mapping-dialog.component';
import { VersionTagComponent } from './version-tag/version-tag.component';
import { ImportSkuDialogComponent } from './import-sku-dialog/import-sku-dialog.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { FileSizePipe } from '../core/pipes/file-size.pipe';
import { DragDropDirective } from './upload-file/drag-drop/drag-drop.directive';
import { ImportSkuMappingsDialogComponent } from './import-sku-mappings-dialog/import-sku-mappings-dialog.component';
import { ViewChangeComponent } from './view-change/view-change.component';
import { ViewChangeTableCellComponent } from './view-change-table-cell/view-change-table-cell.component';
import { ScrollToPositionDirective } from './job-products-tree/scroll-to-position.directive';

@NgModule({
  declarations: [
    GenericSearchComponent,
    ConfirmationDialogComponent,
    ProductCategoryComponent,
    ProductSubcategoryComponent,
    ProductComponent,
    ProductEditingDialogComponent,
    JobProductsTreeComponent,
    ProductSearchComponent,
    LoadingComponent,
    AddProductDialogComponent,
    NoteComponent,
    NewNoteComponent,
    LocationPipe,
    NotePanelComponent,
    CategorySelectorComponent,
    SubcategorySelectorComponent,
    UnitSelectorComponent,
    SkuMappingItemComponent,
    FilterPipe,
    SkuMappingDialogComponent,
    VersionTagComponent,
    ImportSkuDialogComponent,
    UploadFileComponent,
    DragDropDirective,
    FileSizePipe,
    ImportSkuMappingsDialogComponent,
    ViewChangeComponent,
    ViewChangeTableCellComponent,
    ScrollToPositionDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule
  ],
  exports: [
    GenericSearchComponent,
    ConfirmationDialogComponent,
    ProductCategoryComponent,
    ProductSubcategoryComponent,
    JobProductsTreeComponent,
    ProductSearchComponent,
    LoadingComponent,
    NoteComponent,
    NewNoteComponent,
    LocationPipe,
    NotePanelComponent,
    SkuMappingItemComponent,
    VersionTagComponent,
    FilterPipe,
    UploadFileComponent
  ]
})
export class SharedModule { }
