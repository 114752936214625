import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EstimateLineModel } from '../models/EstimateLineModel';
import { JobSuperDivisionModel } from '../models/JobSuperDivisionModel';
import { JobModel } from '../models/JobModel';
import { JobDetailsModel } from '../models/JobDetailsModel';
import { ProductDetail } from '../models/ProductDetail';

@Injectable({
  providedIn: 'root',
})
export class JobDetailsService {
  private jobProductDetails: JobSuperDivisionModel[];
  private jobHeaderDetails: JobModel;

  private jobDetailsSubject: BehaviorSubject<JobSuperDivisionModel[]> =
    new BehaviorSubject<JobSuperDivisionModel[]>([]);

  private productSubject: Subject<EstimateLineModel> = new Subject<EstimateLineModel>();

  setJobDetails(jobDetails: JobDetailsModel) {
    this.jobHeaderDetails = jobDetails;
    this.jobProductDetails = jobDetails.superDivisions;
    this.jobDetailsSubject.next(this.jobProductDetails);
  }

  setjobProductDetails(jobSuperDivisions: JobSuperDivisionModel[]) {
    this.saveExpandedCategoryToSessionStorage();
    this.jobProductDetails = jobSuperDivisions;
    this.jobDetailsSubject.next(this.jobProductDetails);
  }

  updateProductInTree(productItem: EstimateLineModel) {
    this.productSubject.next(productItem);
  }

  getProductByID(id: number): Promise<ProductDetail | undefined> {
    return new Promise((resolve) => {
      let product;
      this.jobProductDetails.forEach((category) => {
        category.assemblyDivisions?.forEach((subcategory) => {
          const foundProduct = subcategory.estimateLines?.find(
            (product) => product.id === id
          );
          if (foundProduct) {
            product = {
              category: category.name,
              subcategory: subcategory.name,
              ...foundProduct,
            };
          }
        });
      });
      resolve(product);
    })

  }

  deleteProductById(productId: number): void {
    const subcategoryWithProduct = this.jobProductDetails
      .flatMap((category) =>
        category.assemblyDivisions ? category.assemblyDivisions : []
      )
      .find(
        (subcategory) =>
          subcategory.estimateLines &&
          subcategory.estimateLines.some((product) => product.id === productId)
      );

    if (subcategoryWithProduct?.estimateLines) {
      const productIndex = subcategoryWithProduct.estimateLines.findIndex(
        (p: EstimateLineModel) => p.id === productId
      );
      subcategoryWithProduct.estimateLines?.splice(productIndex, 1);
    }
  }

  deleteProducts(productIdList: number[]) {
    //Saving expanded categories to session, so these categories expands again after UI re-renders
    this.saveExpandedCategoryToSessionStorage();

    productIdList.map((id) => this.deleteProductById(id));
    this.removeEmptySubcategory();
    this.removeEmptyCategory();
  }

  addProductBySubcategoryId(
    subcategoryId: number,
    newProduct: EstimateLineModel
  ) {
    const subcategory = this.jobProductDetails
      .flatMap((category) =>
        category.assemblyDivisions ? category.assemblyDivisions : []
      )
      .find((subcategory) => subcategory.id === subcategoryId);

    if (subcategory) {
      subcategory.estimateLines?.push(newProduct);
    }
    this.jobDetailsSubject.next(this.jobProductDetails);
  }

  jobDetailsChangeListener(): Observable<JobSuperDivisionModel[]> {
    return this.jobDetailsSubject.asObservable();
  }

  productUpdateListener(): Observable<EstimateLineModel> {
    return this.productSubject.asObservable();
  }

  getJobDetail() {
    return this.jobHeaderDetails;
  }

  getNotes() {
    return this.jobProductDetails.find((category) => category.name === 'Notes');
  }

  private removeEmptySubcategory() {
    // Early return if no empty subcategories
    if (!this.jobProductDetails.some(category => category.assemblyDivisions && category.assemblyDivisions.some(sub => !sub.estimateLines?.length))) {
      return;
    }

    // Create set of empty subcategories for efficient lookup
    const emptySubcategoryIds = new Set(this.jobProductDetails
      .flatMap(category => category.assemblyDivisions?.filter(sub => !sub.estimateLines?.length) ?? [])
      .map(sub => sub.id));

    // Update each category in place
    this.jobProductDetails.forEach(category => {
      if (category.assemblyDivisions) {
        category.assemblyDivisions = category.assemblyDivisions.filter(sub => !emptySubcategoryIds.has(sub.id));
      }
    });
  }

  private removeEmptyCategory() {
    this.jobProductDetails = this.jobProductDetails.filter((category) => {
      return category.assemblyDivisions?.length !== 0;
    });
  }

  private saveExpandedCategoryToSessionStorage(){
    const expandList = sessionStorage.getItem('expandList');
    const nodesToExpand = expandList ? JSON.parse(expandList) : [];
    sessionStorage.setItem('expandListBeforeOperation', JSON.stringify(nodesToExpand));
  }
}
