<ng-container>
    <div class="top-action-box" [ngClass]="{'justify-content-end':isJobProductTreeReadonly}">
        <div [hidden]="isJobProductTreeReadonly">
            <mat-slide-toggle class="toggle-cls" #bulkDeleteToggle (change)="showDeleteOptions()"> Bulk Delete </mat-slide-toggle>
            <span [hidden]="enableBulkDelete">
                <button mat-stroked-button [color]="btnClr" class="btn" *ngIf="btnDisable; else raisedBtn" [disabled]="btnDisable" matTooltip="Delete"> Delete </button>
                <ng-template #raisedBtn>
                    <button mat-raised-button color="accent" class="btn font-clr" (click)="bulkDelete()" matTooltip="Delete"> Delete </button>
                </ng-template>
                <button mat-stroked-button [color]="btnClr" class="btn" (click)="cancelDelete()" [disabled]="btnDisable" matTooltip="Cancel" > Cancel </button>
            </span>
        </div>
        <div>
            <button [hidden]="isJobProductTreeReadonly" mat-raised-button color="accent" class="btn" (click)="bulkImportProducts()" matTooltip="Bulk import new products" > Import SKU </button>
            <button [hidden]="isJobProductTreeReadonly" mat-raised-button color="accent" class="btn" (click)="addProduct()" matTooltip="Add new product" > Add SKU </button>
            <button mat-raised-button color="accent" class="btn" (click)="openNotes()" matTooltip="Open note" > Note </button>

        </div>
   
    </div>
    
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="product-tree" appScrollToPosition>
        <mat-tree-node *matTreeNodeDef="let node" [ngClass]="{'selected-node': isSelected(node)}">
            <app-product-category [category]="node.products" [isProductCategroyReadonly]="isJobProductTreeReadonly"  class="flex-grow-1"></app-product-category>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="justify-content-start flex-grow-1" (click)="toggleExpandIcon(treeControl.isExpanded(node), node)">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <span>
                <span [hidden]="hideCheckbox">
                    <mat-checkbox class="mat-icon-rtl-mirror" (change)="checkItems($event.checked, node)" #catCheckBox></mat-checkbox>
                </span>
                <h3 class="cat-name">{{node.name}}</h3>
            </span>
        </mat-tree-node>
    </mat-tree>
</ng-container>