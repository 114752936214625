import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { LandingPageComponent } from './landing-page.component';

export const HOME_ROUTE: Route = {
  path: '',
  component: LandingPageComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: 'myJobs',
      loadChildren: () => import('../my-jobs/my-jobs.module').then(m => m.MyJobsModule)
    },
    {
      path: 'allJobs',
      loadChildren: () => import('../all-jobs/all-job.module').then(m => m.AllJobsModule)
    },
  ]
};
