import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, finalize } from 'rxjs';
import { Router } from '@angular/router';
import { JobModel } from 'src/app/core/models/JobModel';
import { AlertService } from 'src/app/core/services/alert.service';
import { DataTransferService } from 'src/app/core/services/data-transfer.service';
import { JobService } from 'src/app/core/services/job.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-new-job-dialog',
  templateUrl: './new-job-dialog.component.html',
  styleUrls: ['./new-job-dialog.component.scss'],
})
export class NewJobDialogComponent implements OnDestroy {
  choosenJob: JobModel;
  solidBuilderFile: File;
  specialTimberSheet: File;

  subscriptions: Subscription = new Subscription();

  firstFormGroup = this.formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this.formBuilder.group({
    secondCtrl: new FormControl(false, [Validators.requiredTrue]),
  });
  thirdFormGroup = this.formBuilder.group({
    thirdCtrl: [false, Validators.requiredTrue],
  });

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewJobDialogComponent>,
    private dataTransferService: DataTransferService,
    private jobService: JobService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private route: Router
  ) {}

  submit() {
    if (!this.choosenJob.id) {
      return;
    }
    this.loadingService.startLoading();
    this.subscriptions.add(
      this.jobService
        .createNewJob(this.choosenJob.id, {
          solidBuilderFile: this.solidBuilderFile,
          specialTimberFile: this.specialTimberSheet,
        })
        .pipe(finalize(() => this.loadingService.endLoading()))
        .subscribe({
          next: () => {
            this.dataTransferService.publishNewJobEvent();
            this.alertService.showSuccessMessage(
              'Successfully imported the Job'
            );
            this.dialogRef.close();
            this.route.navigate(['job-details', this.choosenJob.id]);
          },
        })
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  onJobNumberChosen(job: JobModel) {
    const firstCtrl = this.firstFormGroup.get('firstCtrl');
    if (firstCtrl && job.projectNumber) {
      this.choosenJob = job;
      firstCtrl.setValue(job.projectNumber);
    }
  }

  onUploadSolidBuilderFile(file: File) {
    this.solidBuilderFile = file;
    const secondCtrl = this.secondFormGroup.get('secondCtrl');
    if (secondCtrl) {
      secondCtrl.setValue(true);
    }
  }

  onUploadSpecialTimberSheet(file: File) {
    this.specialTimberSheet = file;
    const thirdCtrl = this.thirdFormGroup.get('thirdCtrl');
    if (thirdCtrl) {
      thirdCtrl.setValue(true);
    }
  }

  onDeleteSolidBuilderFile() {
    const secondCtrl = this.secondFormGroup.get('secondCtrl');
    if (secondCtrl) {
      secondCtrl.setValue(false);
    }
  }

  onDeleteSpecialTimberSheet() {
    const thirdCtrl = this.thirdFormGroup.get('thirdCtrl');
    if (thirdCtrl) {
      thirdCtrl.setValue(false);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkFirstStepValidity(): boolean {
    let isValid = true;

    if (
      this.firstFormGroup.valid &&
      this.choosenJob &&
      !this.choosenJob.importedById
    ) {
      isValid = false;
    }

    return isValid;
  }
}
