<mat-form-field appearance="outline">
  <input *ngIf="searchEvent === 'keypress'; else enter" matInput [(ngModel)]="searchInput" (ngModelChange)="search()" placeholder="Search by Solid Builder Sku(s) or ACE Sku(s)">
  <ng-template #enter>
    <input matInput [(ngModel)]="searchInput" (keyup.enter)="search()" placeholder="Search by Job Id">
  </ng-template>
  <button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Clear" (click)="searchInput='';search()" matTooltip="Clear">
    <mat-icon>close</mat-icon>
  </button>
  <button color="accent" matSuffix mat-icon-button aria-label="Search" (click)="search()" matTooltip="Search">
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>