import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';


import { LandingPageComponent } from './landing-page.component';
import { AngularMaterialModule } from '../core/modules/angular-material/angular-material.module';

import { HOME_ROUTE } from './landing-page-routing.module';
import { Auth0Module } from '../auth0/auth0.module';
import { SharedModule } from "../shared-components/shared.module";

@NgModule({
    declarations: [LandingPageComponent],
    exports: [LandingPageComponent],
    imports: [
        CommonModule,
        AngularMaterialModule,
        Auth0Module,
        MatDialogModule,
        RouterModule.forChild([HOME_ROUTE]),
        SharedModule
    ]
})
export class LandingPageModule { }
