import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AdminGuard } from './core/guards/admin-gaurd.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/myJobs',
    pathMatch: 'full',
  },
  {
    path: 'job-details/:id',
    loadChildren: () => import('./job-details-page/job-details.module').then(m => m.JobDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'skuMappings',
    loadChildren: () => import('./sku-mappings-page/sku-mappings-page.module').then(m => m.SkuMappingsPageModule),
    canActivate: [AuthGuard, AdminGuard]
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
