import { Injectable } from '@angular/core';
import { SuperDivisionModel } from '../models/SuperDivisionModel';
import { Observable } from 'rxjs';
import { Constants } from '../constants/constants';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root',
})
export class DivisionService {
  constructor(private apiRequestService: ApiRequestService) {}

  getSuperDivisions(): Observable<SuperDivisionModel[]> {
    return this.apiRequestService.get<SuperDivisionModel[]>(
      Constants.SUPER_DIVISION_API_URL,
      undefined, //No request options
      Constants.GET_CATEGORIES_ERROR
    );
  }
}
