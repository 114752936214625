import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, map } from 'rxjs';
import { Constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-unit-selector',
  templateUrl: './unit-selector.component.html',
  styleUrls: ['./unit-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UnitSelectorComponent
    }]

})
export class UnitSelectorComponent implements OnInit, ControlValueAccessor {
  @Input()
  label = 'Unit';

  @Input() removeLabel: boolean;

  @Output() unitChangeEvent = new EventEmitter<string | null>();

  @Output() unitValid = new EventEmitter<boolean>();

  unitControl = new FormControl<string>('', {
    validators: [
      Validators.required,
      this.autocompleteValidator,
      Validators.pattern(/^[A-Za-z]+$/)
    ]
  });
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.filteredOptions = this.unitControl.valueChanges.pipe(
      map(value => {
        const unit = typeof value === 'string' ? value : '';
        return unit ? this.filter(unit) : Constants.UNITS.slice();
      }),
    );
  }

  autocompleteValidator(control: FormControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    return Constants.UNITS.includes(value) ? null : { invalidAutocomplete: true };
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    this.unitChangeEvent.emit(this.unitControl.value);
    this.propagateChange(event.option.value);
  }

  writeValue(defaultSelection: string): void {
    this.unitControl.setValue(defaultSelection);
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(_fn: any): void {
    //Not used
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled?this.unitControl.disable():this.unitControl.enable();

  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  // eslint-disable-next-line
  private propagateChange = (_: any) => {
    // Placeholder
  };

  private filter(unit: string): string[] {
    const filterValue = unit.toLowerCase();
    return Constants.UNITS.filter(option => option.toLowerCase().includes(filterValue));
  }

  onInputChange() {
    this.unitChangeEvent.emit(this.unitControl.value);
    this.unitValid.emit(this.unitControl.value ? Constants.UNITS.includes(this.unitControl.value) : false);
  }

}
