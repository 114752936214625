<h1 mat-dialog-title class="card-title">Product Details</h1>
<h2 mat-dialog-title>Sku - {{ sku }}</h2>
<div mat-dialog-content class="p-t-20">
  <form [formGroup]="form" class="flex-column">
    <app-category-selector formControlName="category"
      (selectCategory)="onSelectCategory($event)"></app-category-selector>

    <app-subcategory-selector formControlName="subcategory" [subcategories]="subCategories"
      (selectSubcategory)="onSelectSubcategory($event)"></app-subcategory-selector>
  </form>

</div>
<div mat-dialog-actions class="p-x-24 justify-content-between footer">
  <div>
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!form.valid">
      <span *ngIf="!savingChange; else savingChangeSpinner">Save</span>
      <ng-template #savingChangeSpinner>
        <mat-spinner mode="indeterminate" diameter="30" color="accent"></mat-spinner>
      </ng-template>
    </button>
    <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
  </div>
</div>
