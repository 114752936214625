import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { JobNoteModel } from '../models/JobNoteModel';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  notes: JobNoteModel[];

  private noteDataSubject: BehaviorSubject<JobNoteModel[]> = new BehaviorSubject<JobNoteModel[]>([]);

  private noteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setNoteData(notes: JobNoteModel[]){
    this.notes = notes;    
    this.noteDataSubject.next([...this.notes]);
  }

  insertNewNote(posIndex: number, newNote: JobNoteModel){
    this.notes.splice(posIndex,0,newNote);
    this.noteDataSubject.next([...this.notes]);
  }

  updateNote(noteId: number, updatedNote: string): void {
    const noteToUpdate = this.notes.find(note => note.id === noteId);
  
    if (noteToUpdate) {
      noteToUpdate.note = updatedNote;
      this.noteDataSubject.next([...this.notes]);
    }
  }

  deleteNote(noteId: number){
    const noteIndex = this.notes.findIndex(note => note.id == noteId);
    this.notes.splice(noteIndex,1);
    this.noteDataSubject.next([...this.notes]);
  }

  openNote() {
    this.noteSubject.next(true);
  }

  closeNote(){
    this.noteSubject.next(false);
  }

  listenNoteDataUpdateEvent(){
    return this.noteDataSubject.asObservable();
  }

  listenNoteEvent(): Observable<boolean> {
    return this.noteSubject.asObservable();
  }
}
