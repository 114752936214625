import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { SkuMappingModel } from 'src/app/core/models/SkuMappingModel';
import { SkuMappingDialogComponent } from '../sku-mapping-dialog/sku-mapping-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Constants } from 'src/app/core/constants/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sku-mapping-item',
  templateUrl: './sku-mapping-item.component.html',
  styleUrls: ['./sku-mapping-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SkuMappingItemComponent
    }
  ]
})
export class SkuMappingItemComponent implements OnDestroy, ControlValueAccessor, AfterViewInit, AfterViewChecked{
  @ViewChild('checkBox') checkBox:MatSlideToggle;
  @ViewChild('solidBuilderDescription', { static: false }) solidBuilderDescription: ElementRef;
  @ViewChild('description', { static: false }) description: ElementRef;
  @ViewChild('productUseDescription', { static: false }) productUseDescription: ElementRef;

  @Input()
  skuMapping: SkuMappingModel;

  @Input()
  hideCheckBox: boolean;

  checkBoxControl = new FormControl();

  rowHeight: number

  subscription: Subscription = new Subscription();

  constructor(private skuMappingDialog: MatDialog, private cdRef : ChangeDetectorRef) { }

  //Required as the list height will be adjusted dynamically
  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    if (this.description && this.productUseDescription && this.solidBuilderDescription) {
      const descriptionHeight = this.description.nativeElement.offsetHeight;
      const productUseDescriptionHeight = this.productUseDescription.nativeElement.offsetHeight;
      const solidBuilderDescriptionHeight = this.solidBuilderDescription.nativeElement.offsetHeight;

      // Set rowHeight based on the greater offsetHeight plus 15
      this.rowHeight = Math.max(descriptionHeight, productUseDescriptionHeight, solidBuilderDescriptionHeight) + 15;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onEdit() {
    const dialogRef = this.skuMappingDialog.open(
      SkuMappingDialogComponent, {
      data: this.skuMapping,
      width: '40%',
      disableClose: true,
      autoFocus: false
    }
    );

    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if(result.event && result.event === Constants.MAPPING_UPDATED)
        Object.assign(this.skuMapping,result.data);      
      }
    ));
  }

  checkBoxChange() {    
    this.propagateChange(this.checkBox.checked);
  }

  writeValue(defaultSelection: boolean): void {
    this.checkBoxControl.patchValue(defaultSelection);
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(onTouched: any): void {
    //Not used
  }
  
  // eslint-disable-next-line
  setDisabledState?(isDisabled: boolean): void {
    //Not used
  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  // eslint-disable-next-line
  private propagateChange = (_: any) => {
    // Placeholder
  };

}
