<mat-card class="row-item" (click)="onEdit()">
    <mat-card-content class="row-content">
        
        <mat-grid-list cols="13" [rowHeight]="rowHeight">
            <mat-grid-tile class="checkbox" colspan="1">
                <span [hidden]="hideCheckBox">
                    <mat-checkbox #checkBox [formControl]="checkBoxControl" (change)="checkBoxChange()" (click)="$event.stopPropagation()"></mat-checkbox>
                </span>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <div class="flex-grow-1">
                    <span>{{skuMapping.solidBuilderSku}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
                <div #solidBuilderDescription class="flex-grow-1 description">
                    <span>{{skuMapping.solidBuilderDescription}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <div class="flex-grow-1">
                    <span>{{skuMapping.sourceUnitOfMeasure}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <div class="flex-grow-1">
                    <span>{{skuMapping.productCode}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
                <div #description class="flex-grow-1 description">
                    <span>{{skuMapping.product.description}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
                <div #productUseDescription class="flex-grow-1 description">
                    <span>{{skuMapping.productUseDescription}}</span>
                </div>
            </mat-grid-tile>
            
        </mat-grid-list>

    </mat-card-content>
</mat-card>
