import { Component, Input } from '@angular/core';
import { JobSuperDivisionModel } from 'src/app/core/models/JobSuperDivisionModel';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss']
})
export class ProductCategoryComponent {
  @Input()
  category: JobSuperDivisionModel;

  @Input()
  isProductCategroyReadonly: boolean;
  
}
