import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  files: File[] = [];
  error: string;

  @Input()
  minimumLines = 1;

  @Output()
  uploadFileEvent = new EventEmitter<File>();

  @Output()
  fileDeleteEvent = new EventEmitter();

  onFileDropped(files: FileList) {
    if (files.length === 1) {
      if (files[0].type === 'text/csv') {
        this.prepareFilesList(files);
      }
    }
  }

  onBrowseFile() {
    document.getElementById('fileInput')?.click();
  }

  fileBrowseHandler(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (files?.length === 1) {
      this.prepareFilesList(files);
    }
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileDeleteEvent.emit();
  }

  async prepareFilesList(files: FileList) {
    if (!files || files.length !== 1) {
      return;
    }

    //FileList only contains one file
    const file = files[0];

    if (!this.fileSizeCheck(file) || !(await this.checkCSVLineNumber(file))) {
      return;
    }

    this.files = Array.from(files);
    this.error = '';
    this.uploadFileEvent.emit(this.files[0]);
  }

  async checkCSVLineNumber(file: File) {
    return file.text().then((result) => {
      const csvLineNumber = result.split('\n').length;
      if (csvLineNumber < this.minimumLines) {
        console.log('line number <');
        this.error = `CSV file must contains at least ${this.minimumLines} lines.`;
        return false;
      }
      return true;
    });
  }

  fileSizeCheck(file: File) {
    if (file.size === 0) {
      console.log('file size 0');
      this.error = `Empty CSV file.`;
      return false;
    }
    return true;
  }
}
