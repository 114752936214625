import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchService } from 'src/app/core/services/search.service';
import { Location } from '@angular/common';

interface LocationState {
  searchText: string;
}

@Component({
  selector: 'app-generic-search',
  templateUrl: './generic-search.component.html',
  styleUrls: ['./generic-search.component.scss']
})
export class GenericSearchComponent implements OnInit, OnDestroy {

  searchInput: string;
  private searchStringSubscription: Subscription;

  @Input()
  searchEvent: string;

  constructor(private searchService: SearchService, private location: Location) { }

  ngOnInit() {
    this.searchStringSubscription = this.searchService.clearSearchString.subscribe(() => {
      this.searchInput = '';
    })

    this.searchInput = (this.location.getState() as LocationState).searchText;
    this.search();
  }

  search() {
    this.searchService.setSearchString(this.searchInput);
  }

  ngOnDestroy() {
    this.searchStringSubscription.unsubscribe();
  }
}
