<app-loading></app-loading>
<div class="dialog-header">
  <h3>Import SKU Mappings</h3>
  <button mat-icon-button (click)="closeImportSkuMappingDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="dialog-content-height">
  <mat-stepper labelPosition="bottom" linear="true" #stepper>
    <mat-step [stepControl]="firstControlGroup" [editable]="false">
      <ng-template matStepLabel>Upload File</ng-template>
      <form [formGroup]="firstControlGroup">
        <div class="content-height">
          <app-upload-file
            [minimumLines]="10"
            (uploadFileEvent)="onUploadFile($event)"
            (fileDeleteEvent)="onDeleteFile()"
          ></app-upload-file>
        </div>
        <div class="flex justify-content-end">
          <button
            mat-raised-button
            color="primary"
            [disabled]="!firstControlGroup.valid"
            (click)="submit()"
            class="margin-right-10"
          >
            <span *ngIf="!savingChange; else savingChangeSpinner">Next</span>
            <ng-template #savingChangeSpinner>
              <mat-spinner mode="indeterminate" diameter="30" color="accent"></mat-spinner>
          </ng-template>
          </button>
          <button
            mat-stroked-button
            color="primary"
            (click)="closeImportSkuMappingDialog()"
          >
            Cancel
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step editable="false">
      <ng-template matStepLabel>View Changes</ng-template>
      <div class="content-height">
        <app-view-change [data]="skuMappingChanges" (changeSelectionEvent)="onSelectSkuMapping($event)"></app-view-change>
      </div>
      <div class="flex justify-content-end">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!secondControlGroup.valid"
          matStepperNext
          (click)="saveSKUMappingChanges()"
          class="margin-right-10"
        >
          <span *ngIf="!savingChange; else savingChangeSpinner">Import</span>
          <ng-template #savingChangeSpinner>
            <mat-spinner mode="indeterminate" diameter="30" color="accent"></mat-spinner>
        </ng-template>
        </button>
        <button
          mat-stroked-button
          color="primary"
          matStepperNext
          (click)="closeImportSkuMappingDialog()"
        >
          Cancel
        </button>
      </div>
      <div class="flex justify-content-end"></div>
    </mat-step>
  </mat-stepper>
</div>
