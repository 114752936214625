<div [hidden]="!isActive">
    <mat-form-field appearance="outline" class="product-selector" subscriptSizing="dynamic" (keydown)="onKeyDown($event)">
        <mat-label>
            Search
        </mat-label>
        <input type="text" #inputElement  matInput [formControl]="productSearchControl"
            placeholder="Search product by Id or Description" [matAutocomplete]="auto" (input)="onProductChange()">
        <div matSuffix class="flex align-items-center justify-content-center w-48">
            <mat-spinner class="spinner" *ngIf="isLoading; else notSearching" matSuffix [diameter]="30"></mat-spinner>
            <ng-template #notSearching>
                <button matSuffix mat-icon-button (click)="clearSelection()">
                    <mat-icon>clear</mat-icon>
                </button>
            </ng-template>
        </div>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (opened)="autoCompleteScroll()">
            <mat-option *ngFor="let product of filteredProducts" [value]="product.productCode"
                (onSelectionChange)="onProductSelection(product)">
                <span class="option-text">{{ product.productCode }} - {{ product.description }}</span>
            </mat-option>
            <mat-option [disabled]="true" *ngIf="this.productTotal ===0 && isSearchCompleted">Product not
                found!</mat-option>
            <div class="load-items" *ngIf="this.isLoadingItems"><mat-spinner class="spinner" matSuffix [diameter]="20"></mat-spinner></div>
            <div *ngIf="this.showNoMoreItems" class="no-more-items">No more items!</div>
        </mat-autocomplete>
    </mat-form-field>
</div>
