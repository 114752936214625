import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Subscription, finalize } from 'rxjs';
import { Constants } from 'src/app/core/constants/constants';
import { SkuMappingModel } from 'src/app/core/models/SkuMappingModel';
import { SkuMappingChangeModel } from 'src/app/core/models/skuMappingChangeModel';
import { AlertService } from 'src/app/core/services/alert.service';
import { SkuMappingService } from 'src/app/core/services/sku-mapping.service';

@Component({
  selector: 'app-import-sku-mappings-dialog',
  templateUrl: './import-sku-mappings-dialog.component.html',
  styleUrls: ['./import-sku-mappings-dialog.component.scss'],
})
export class ImportSkuMappingsDialogComponent implements OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;

  file: File;

  skuMappingChanges: SkuMappingChangeModel[];
  selectedSkuMappingChanges: SkuMappingModel[];

  savingChange = false;

  firstControlGroup: FormGroup = this.formBuilder.group({
    firstControl: [false, Validators.requiredTrue],
  });

  secondControlGroup: FormGroup = this.formBuilder.group({
    secondControl: [false, Validators.requiredTrue],
  });

  subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ImportSkuMappingsDialogComponent>,
    private skuMappingService: SkuMappingService,
    private alertService: AlertService,
    private router: Router
  ) {}

  closeImportSkuMappingDialog() {
    this.dialogRef.close();
  }

  submit() {
    this.savingChange = true;
    this.subscriptions.add(
      this.skuMappingService
        .uploadSkuMappingCsv(this.file)
        .pipe(
          finalize(() => {
            this.savingChange = false;
          })
        )
        .subscribe((result) => {
          this.skuMappingChanges = result;
          this.stepper.next();
        })
    );
  }

  onDeleteFile() {
    this.firstControlGroup.patchValue({
      firstControl: false,
    });
  }

  onUploadFile(file: File) {
    this.firstControlGroup.patchValue({
      firstControl: true,
    });
    this.file = file;
  }

  saveSKUMappingChanges() {
    this.savingChange = true;
    this.subscriptions.add(
      this.skuMappingService
        .bulkUploadSkuMappings(this.selectedSkuMappingChanges)
        .pipe(
          finalize(() => {
            this.savingChange = false;
          })
        )
        .subscribe({
          next: () => {
            this.dialogRef.close({event: Constants.MAPPING_ADDED});
            this.alertService.showSuccessMessage('SKUs added Successfully.');
          },
        })
    );
  }

  onSelectSkuMapping(skuMappingsSelected: SkuMappingChangeModel[]) {
    const selection: SkuMappingModel[] = skuMappingsSelected.map(
      (skuMapping) => skuMapping.new
    );
    this.selectedSkuMappingChanges = selection;
    this.secondControlGroup.patchValue({
      secondControl: this.selectedSkuMappingChanges.length > 0,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
