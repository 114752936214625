import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService {
  constructor(private http: HttpClient, private alertService: AlertService) {}

  get<T>(path: string, options?: object, errorMessage?: string): Observable<T> {
    return this.handleRequest(this.http.get<T>(path, options), errorMessage);
  }

  post<T>(
    path: string,
    body: object,
    options?: object,
    errorMessage?: string
  ): Observable<T> {
    return this.handleRequest(
      this.http.post<T>(path, body ? body : {}, options),
      errorMessage
    );
  }

  put<T>(
    path: string,
    body: object,
    options?: object,
    errorMessage?: string
  ): Observable<T> {
    return this.handleRequest(
      this.http.put<T>(path, body ? body : {}, options),
      errorMessage
    );
  }

  delete<T>(
    path: string,
    options?: object,
    errorMessage?: string
  ): Observable<T> {
    return this.handleRequest(this.http.delete<T>(path, options), errorMessage);
  }

  private handleRequest<T>(
    observable: Observable<T>,
    errorMessage?: string
  ): Observable<T> {
    return observable.pipe(
      catchError((error) => {
        console.log(error, 'API Error');
        this.alertService.showErrorMessage(
          errorMessage ? errorMessage : error.message
        );
        return throwError(() => error);
      })
    );
  }
}
