import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {
  Observable,
  Subject,
  Subscription,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs';
import { Product } from 'src/app/core/models/Product';
import { ProductSearchResult } from 'src/app/core/models/ProductSearchResult';
import { ProductService } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit ,OnDestroy {
  @Input() isActive: boolean;
  @Output() productChangedEvent = new EventEmitter<Product>();
  @Output() clearEvent = new EventEmitter();
  @ViewChild('auto') autoComplete: MatAutocomplete;
  @Output() cancelEvent = new EventEmitter();
  @ViewChild('inputElement') inputElement: ElementRef;

  searchTerm: string;
  isSearchCompleted = false;
  isLoading = false;
  productSearchControl = new FormControl();
  products: Observable<Product[]>;
  filteredProducts: Product[] = [];
  productTotal: number;
  pageCount = 0;
  noOfRecPerPage = 20;
  isLoadingItems = false;
  showNoMoreItems = false;

  private searchString = new Subject<string>();
  private subscriptions = new Subscription();

  constructor(
    private productService: ProductService,
  ) {}

  ngOnInit(): void {
    this.searchProducts();
  }


  searchProducts() {
    this.subscriptions.add(
      this.searchString
        .pipe(
          distinctUntilChanged(),
          tap(() => {
            this.isLoading = true;
            this.filteredProducts = [];
            this.isSearchCompleted = false;
          }),
          switchMap((searchString: string) =>
            this.productService.searchProduct(searchString, this.noOfRecPerPage, this.pageCount)
          )
        )
        .subscribe({
          next: (productResults: ProductSearchResult) => {
            this.filteredProducts = productResults.items;
            this.productTotal = productResults.total;
            this.isSearchCompleted = true;
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
            this.searchProducts();
          },
        })
    );
  }

  onProductChange() {
    this.resetValues();
    this.productSearchControl?.value.length > 1 &&
    this.searchString.next(
      this.productSearchControl?.value
    );
  }

  onProductSelection(product: Product) {
    this.productChangedEvent.emit(product);
    this.productSearchControl.setValue("");

  }

  resetValues() {
    this.pageCount = 0;
    this.filteredProducts = [];
    this.isLoadingItems = false;
    this.showNoMoreItems = false;
    this.removeScrollEventListener();
  }

  clearSelection() {
    this.resetValues();
    this.productSearchControl.patchValue('');
    this.isSearchCompleted = false;
    this.clearEvent.emit();
  }

  autoCompleteScroll(): void {
    setTimeout(() => {
      if (this.autoComplete && this.autoComplete.panel && this.autoComplete.panel.nativeElement) {
        this.autoComplete.panel.nativeElement.addEventListener(
          'scroll',
          this.onScroll.bind(this)
        );
      }
    });
  }

  onScroll(event: Event) {
    const htmlElement = event.target as HTMLElement;
    const threshold = htmlElement && (htmlElement.scrollHeight - htmlElement.clientHeight);
    const current = htmlElement.scrollTop;
    const noOfPages = Math.round(this.productTotal / this.noOfRecPerPage) - 1;

    if (current != 0 && threshold != 0 && current === threshold && this.productSearchControl.value) {
      this.isLoadingItems = true;
      this.showNoMoreItems = false;

      if(this.pageCount < noOfPages) {
        this.pageCount++;
        this.subscriptions.add(
          this.productService
          .searchProduct(this.productSearchControl.value, this.noOfRecPerPage, this.pageCount)
          .subscribe({
            next: (productResults: ProductSearchResult) => {
              this.isLoadingItems = false;
              this.showNoMoreItems = false;
              const newArr = [...this.filteredProducts, ...productResults.items];
              this.filteredProducts = newArr;
            },
            error: () => {
              this.pageCount--;
              this.isLoadingItems = false;
              this.showNoMoreItems = false;
              this.onScroll(event);
            },
          })
        );
      } else {
        this.isLoadingItems = false;
        this.showNoMoreItems = true;
      }
    }
  }

  removeScrollEventListener() {
    if(this.autoComplete && this.autoComplete.panel && this.autoComplete.panel.nativeElement) {
      this.autoComplete.panel.nativeElement
      .removeEventListener('scroll', this.onScroll);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    this.cancelEvent.emit(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.removeScrollEventListener();
  }
}
