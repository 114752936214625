import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  Subscription,
  switchMap,
  filter,
  debounceTime,
  distinctUntilChanged,
  Subject
} from 'rxjs';
import { JobModel } from 'src/app/core/models/JobModel';
import { JobModelSearchResponseModel } from 'src/app/core/models/JobModelSearchResponseModel';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-search-job',
  templateUrl: './search-job.component.html',
  styleUrls: ['./search-job.component.scss'],
})
export class SearchJobComponent implements OnInit, OnDestroy {
  jobsFound: JobModel[];
  searched = false;
  selectedOption: JobModel;
  isSearching = false;

  readonly search = new Subject<string>();

  searchControl = this.formBuilder.control('');
  private subscription: Subscription = new Subscription();

  @Output()
  jobSearchEvent = new EventEmitter<JobModel>();

  constructor(
    private jobService: JobService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.search
        .pipe(
          filter((searchTerm) => searchTerm.length > 4),
          debounceTime(300),
          distinctUntilChanged(),
          switchMap((searchTerm) => {
            this.searched = true;
            this.isSearching = true;
            return this.jobService.searchBuildItJobs(searchTerm);
          })
        )
        .subscribe((result: JobModelSearchResponseModel) => {
          this.jobsFound = result.items ? result.items : [];
          this.isSearching = false;
        })
    );
  }

  onClear() {
    this.searchControl.patchValue('');
    this.jobsFound = [];
    this.searched = false;
  }

  onSearchInputChange() {
    this.search.next(this.searchControl.value ? this.searchControl.value : '');
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedId = event.option.value;
    const selectedJob = this.jobsFound?.find(
      (job: JobModel) => job.id === selectedId
    );
    if (selectedJob) {
      this.selectedOption = selectedJob;
      if (selectedJob.projectNumber) {
        this.searchControl.setValue(selectedJob.projectNumber);
        this.jobSearchEvent.emit(selectedJob);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
