<mat-card class="gray-background m-b-16 is-focusable" tabindex="0" (keydown)="inlineEditService.isRowBeingEdited(productItem) ? onKeyDown($event, productItem) : null">
    <mat-card-content class="card-content">
        <mat-grid-list cols="12" [rowHeight]="rowHeight" (click)="inlineEditService.isRowBeingEdited(productItem) ? onEdit($event, productItem, 'product') : null">
          <div *ngIf="!this.editProductItem; else editItem">
            <mat-grid-tile class="checkbox-data" colspan="1">
                <span [hidden]="hideCheckbox">
                    <mat-checkbox class="mat-icon-rtl-mirror" (change)="checkItems($event.checked, productItem.id)" #skuCheckbox (click)="$event.stopPropagation()"></mat-checkbox>
                </span>
            </mat-grid-tile>
            <mat-grid-tile class="sku-data" colspan="1">
                <div #sku class="flex-grow-1">
                    <span>{{productItem.sku}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <button mat-mini-fab [ngStyle]="{'background-color': getColour(productItem)}" (click)="$event.stopPropagation()" [matTooltip]="tooltip" matTooltipClass="setFontSize">
                </button>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
                <div #description class="flex-grow-1 description">
                    <span>{{productItem.description}}</span>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
                <div #productUseDescription class="flex-grow-1 use-description">
                    <span>{{productItem.productUseDescription}}</span>
                  </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <span>{{productItem.quantity}} {{productItem.unit}}</span>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
              <button mat-icon-button color="primary" (click)="inlineEditService.isRowBeingEdited(productItem) ? onEdit($event, productItem, 'category') : null" matTooltip="Edit Category" matTooltipClass="setFontSize">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-grid-tile>
          </div>
          <ng-template #editItem>
              <mat-grid-tile class="checkbox-data" colspan="1">
                  <span [hidden]="hideCheckbox">
                      <mat-checkbox class="mat-icon-rtl-mirror" (change)="checkItems($event.checked, productItem.id)" #skuCheckbox (click)="$event.stopPropagation()"></mat-checkbox>
                  </span>
              </mat-grid-tile>
              <form #productForm="ngForm" [formGroup]="productEditForm">
                <mat-grid-tile class="sku-data" colspan="1">
                    <div #sku class="flex-grow-1">
                        <div class="product-search-container">
                          <app-product-search [isActive]="activeProductSearch" (productChangedEvent)="updateProduct($event)" (cancelEvent)="onKeyDown($event, productItem)" (click)="$event.stopPropagation()" ></app-product-search>
                        </div>
                        <div class="flex-sku" *ngIf="!activeProductSearch">
                          <input matInput type="number" class="edit-sku" formControlName="sku" id="sku-input"  (input)="onInputChange()" (click)="$event.stopPropagation()" [class.error]="!productEditForm.get('sku')?.value">
                          <button matSuffix mat-icon-button class="close-button" [disabled]="activeProductSearch"
                          (click)="displayProductSearch()"><mat-icon>search</mat-icon></button>
                        </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                    <button mat-mini-fab [ngStyle]="{'background-color': getColour(productItem)}" (click)="$event.stopPropagation()" [matTooltip]="tooltip" matTooltipClass="setFontSize">
                    </button>
                </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <div #description class="flex-grow-1 description">
                        <textarea matInput type="text" formControlName="description" (click)="$event.stopPropagation()" [class.error]="!productEditForm.get('description')?.value" (input)="onInputChange()" (focus)="onFocus($event)" rows="3" [readonly]="this.productItem.productCode" class="edit-product-item"></textarea>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <div #productUseDescription class="flex-grow-1 use-description">
                        <textarea matInput formControlName="productUseDescription" (focus)="onFocus($event)" (click)="$event.stopPropagation()" type="text" rows="3" class="edit-product-item"></textarea>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <input type="number" (input)="onInputChange()" formControlName="quantity" (focus)="onFocus($event)" id="quantity" class="edit-quantity-unit edit-quantity" [class.error]="!productEditForm.get('quantity')?.value && productEditForm.get('quantity')?.value === 0" (click)="$event.stopPropagation()">
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                    <app-unit-selector *ngIf="!this.productItem.productCode; else readonlyUnit" class="edit-quantity-unit" [removeLabel]="true" formControlName="unit" (unitChangeEvent)="onUnitInputChange($event)" (unitValid)="onUnitValid($event)" (click)="$event.stopPropagation()"></app-unit-selector>
                    <ng-template  #readonlyUnit>
                      <input matInput formControlName="unit" readonly="true" class="edit-quantity-unit edit-quantity">
                    </ng-template>
                  </mat-grid-tile>
                <!-- Icons Save, Delete and Cancel-->
                <mat-grid-tile colspan="1">
                  <button mat-icon-button color="primary" #save type="button" (click)="onSave()" matTooltip="Save" matTooltipClass="setFontSize" [disabled]="this.toDisableSaveBtn || !productEditForm.valid">
                    <mat-icon>save</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" type="button" (click)="onDelete(productItem)" matTooltip="Delete" matTooltipClass="setFontSize" [disabled]="productItem.sku ? false : true">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" #cancel type="button" (click)="onCancel()" matTooltip="Cancel" matTooltipClass="setFontSize">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-grid-tile>
              </form>
          </ng-template>
        </mat-grid-list>
    </mat-card-content>
</mat-card>
