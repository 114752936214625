import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Constants } from "../constants/constants";
import { ApiRequestService } from "./api-request.service";

interface ItemsSelect {
    subCatList?: number[];
    skuList?: number[];
    toSelect: boolean;
}
 
@Injectable({
    providedIn: 'root'
})

export class DeleteService {
    itemsToSelect = {
        subCatList: [],
        skuList: [],
        toSelect: false,
    }
    
    selectedList: number[] = [];  //this list should only contain estimateline IDs
    selectedSubCat: number[] = []; 
    isCheckboxVisible: boolean;
    
    checkBoxVisibilityChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    selectSubCatItems$: BehaviorSubject<ItemsSelect> = new BehaviorSubject<ItemsSelect>(this.itemsToSelect);
    selectSKUItems$: BehaviorSubject<ItemsSelect> = new BehaviorSubject<ItemsSelect>(this.itemsToSelect);
    selectedItemsToDelete$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    
    constructor(private apiRequestService: ApiRequestService)  {
        this.checkBoxVisibilityChange$.subscribe((value) => {
            this.isCheckboxVisible = value;
        });
    }

    toggleCheckboxVisibility() {
        this.checkBoxVisibilityChange$.next(!this.isCheckboxVisible);
    }

    clearSelectedList(){
        this.selectedList = [];
    }

    selectedItemListToDelete(checked: boolean, item: number) {
        if(checked) {                                    
            !this.selectedList.includes(item) && this.selectedList.push(item);            
        } else{
            const selItemIndex = this.selectedList.findIndex((selItem) => selItem === item);            
            if(selItemIndex < 0) return;
            this.selectedList.splice(selItemIndex,1);
        }
        this.selectedItemsToDelete$.next(this.selectedList);
    }

    //API to delete the selected items
    deleteSelectedItems(list: number[]){
        const url = `${Constants.ESTIMATE_LINE_API_URL}/Bulk`;
        return this.apiRequestService.delete(url, { body: list }, Constants.DELETE_SKUS_ERROR);
    }

}