import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private readonly snackBar: MatSnackBar, public dialog: MatDialog) {

  }

  private getSnackBarConfig(css?: string): MatSnackBarConfig {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = 3000;
    config.panelClass = css ? css : '';
    return config;
  }

  public showSuccessMessage(message: string) {
    this.snackBar.open(message, undefined, this.getSnackBarConfig('app-notification-success'));
  }

  public showErrorMessage(message: string) {
    this.snackBar.open(message, undefined, this.getSnackBarConfig('app-notification-error'));
  }

}
