import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-version-tag',
  templateUrl: './version-tag.component.html',
  styleUrls: ['./version-tag.component.scss']
})
export class VersionTagComponent {

  @Input()
  versionNumber?: string | null;

}
