<div *ngIf="files.length === 0;" class="dropzone" appDragDrop (fileDropped)="onFileDropped($event)">
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <button mat-raised-button color="primary" (click)="onBrowseFile()">
        Browse for files
    </button>
    <input type='file' accept=".csv" id="fileInput" (change)="fileBrowseHandler($event)" />
    <mat-error *ngIf="error" class="font-14">
        *{{error}}
    </mat-error>
</div>



<h4 *ngIf="files.length !== 0;" class="margin-bottom-0">File imported:</h4>
<mat-grid-list cols="12" rowHeight="60px" gutterSize="2px" class="file-list" *ngFor="let file of files; let i = index">
    <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-icon fontIcon="description"></mat-icon>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="10" [rowspan]="1">
        <div class="flex-column file-info">
            <b>{{file.name}}</b>
            <div #fileSize class="file-size">{{file.size | fileSize}}</div>
        </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
        <button mat-button color="warn" (click)="deleteFile(i)" matTooltip="Remove">
            <mat-icon fontIcon="close"></mat-icon>
        </button>
    </mat-grid-tile>
</mat-grid-list>