import { Injectable } from '@angular/core';
import { EstimateLineModel } from '../models/EstimateLineModel';

@Injectable({
  providedIn: 'root'
})

export class InlineEditService {
  private currentlyEditedRow: EstimateLineModel | null;

  startEditing(row: EstimateLineModel) {
    this.currentlyEditedRow = row;
  }

  endEditing() {
    this.currentlyEditedRow = null;
  }

  getRowBeingEdited() {
    return this.currentlyEditedRow;
  }

  isRowBeingEdited(row: EstimateLineModel) {
    if(this.currentlyEditedRow?.id === undefined) {
      return true;
    }
    return this.currentlyEditedRow?.id === row.id;
  }
}
