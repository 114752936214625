import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AssemblyDivisionModel } from 'src/app/core/models/AssemblyDivisionModel';
import { JobAssemblyDivisionModel } from 'src/app/core/models/JobAssemblyDivisionModel';

@Component({
  selector: 'app-subcategory-selector',
  templateUrl: './subcategory-selector.component.html',
  styleUrls: ['./subcategory-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SubcategorySelectorComponent
    }
  ]
})
export class SubcategorySelectorComponent implements ControlValueAccessor{
  
  @Input()
  subcategories: JobAssemblyDivisionModel[] = [];

  @Output()
  selectSubcategory: EventEmitter<AssemblyDivisionModel> = new EventEmitter<AssemblyDivisionModel>();

  selectedCategory = new FormControl('');

  disabled = false;

  selectionChanged(event: MatSelectChange) {
    this.emitSubcategory(event.value);
    this.propagateChange(event.value);
  }

  writeValue(defaultSelection: string): void {
    this.selectedCategory.setValue(defaultSelection);
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(onTouched: any): void {
    //Not used
  }
  
  setDisabledState?(isDisabled: boolean): void {
    isDisabled?this.selectedCategory.disable():this.selectedCategory.enable();
  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  // eslint-disable-next-line
  private propagateChange = (_: any) => {
    // Placeholder
  };

  private emitSubcategory(subcategoryName: string){    
    const selectedSubategory = this.subcategories.find(subcategory => subcategory.name === subcategoryName);
    
    if (selectedSubategory) {
      this.selectSubcategory.emit(selectedSubategory);
    }
  }

}
